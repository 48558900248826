<template>
  <div class="c-radio-group" v-if="formItem.type === 'radio-group'">
    <el-form-item :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <el-radio-group v-model="radio" @change="handleChange">
        <el-radio v-for="(item, index) in formItem.radiosConfig" :key="index" :label="item.value"
                  :disabled="item.disabled">{{item.name}}
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <div class="c-radio-group_form" v-show="!$CheckValue(formItem.config)">
      <c-form :config="radioFormConfig" :content="page_content" ref="radioForm" :formShow="true" @submit="submit"
              :submitFlag.sync="submitFlag" :delay="delay">
      </c-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c-radio-group',
  props: {
    formField: [String, Number, Boolean, Array, Object],
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ownCheck: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      radio: '',
      page_content: {},
      submitFlag: true,
      delay: false
    }
  },
  created () {
    this.radio = this.formItem.defaultValue
    this.handleFun(this.formField)
  },
  computed: {
    radioFormConfig () {
      if (this.$CheckValue(this.formItem.config)) {
        return {}
      }
      if (this.radio) {
        let newObj = Object.assign({}, this.formItem.config)
        let obj = this.formItem.config.components.filter(item => {
          return item.ids === this.radio
        })
        newObj.components = obj
        return newObj
      }
      return {}
    }
  },
  methods: {
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleChange (data) {
      let obj
      if (!this.$CheckValue(this.formItem.config)) {
        obj = data
      }
      this.$emit('update:formField', obj)
    },
    submit (form) {
      let arr = []
      if (!this.$CheckValue(this.formItem.config)) {
        let obj = {}
        obj[this.formItem.id] = this.radio
        obj['page_content'] = form.form
        obj['medias'] = form.medias
        arr.push(obj)
      }
      this.$emit('update:formField', arr)
    },
    handleFun (val) {
      if (!this.$CheckValue(val)) {
        if (typeof val === 'string') {
          this.radio = val
        } else if (typeof val === 'object') {
          if (val.length > 0) {
            this.radio = val[0][this.formItem.id]
            this.page_content = val[0].page_content
          } else {
            this.radio = val[this.formItem.id]
            this.page_content = val.page_content
          }
        }
      } else {
        this.radio = this.formItem.defaultValue
        this.$emit('update:formField', this.radio)
      }
    },
    submitForm () {
      this.$refs['radioForm'].submitForm('form')
    }
  },
  watch: {
    formField (val) {
      if (!this.$CheckValue(val)) {
        if (typeof val === 'string') {
          this.radio = val
        } else if (typeof val === 'object') {
          if (val.length > 0) {
            this.radio = val[0][this.formItem.id]
            val[0].page_content = Array.isArray(val[0].page_content) === true ? {} : val[0].page_content
            this.page_content = val[0].page_content
          } else {
            this.radio = val.radio
            this.page_content = val.page_content
          }
        }
      } else {
        this.radio = this.formItem.defaultValue
        this.$emit('update:formField', this.radio)
      }
    },
    submitFlag (val) {
      this.$emit('update:ownCheck', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

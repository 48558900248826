import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/antComponents'
import 'ant-design-vue/dist/antd.css'
import './permission'
import './utils/iconfont'
import './utils/icon'
import './utils/layoutIcon'
import './utils/directive'
import './utils/element'
import './styles/element.scss'
import { setWebSiteConfig } from './utils/tools'
import 'element-ui/lib/theme-chalk/index.css'
import oldAxios from './utils/oldAxios'
import utilFun from '@/utils/function'
import Cookies from 'js-cookie'
import MLoading from './components/c-loading/index.js'

Vue.use(MLoading)
Vue.config.productionTip = false
Vue.prototype.$setWebSite = setWebSiteConfig
Vue.prototype.$Cookies = Cookies
Vue.prototype.$CheckValue = utilFun.checkValue
Vue.prototype.$axios = oldAxios

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="c-form-input">
    <!--区分多个input-->
    <el-form-item v-if="formItem.type === 'textarea'" :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <el-input type="textarea" v-model="form[formItem.id]" :rows="formItem.rows" :name="formItem.id" :placeholder="formItem.placeholder"></el-input>
      <!--<div class="desc">-->
        <!--{{formItem.desc}}-->
      <!--</div>-->
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'c-form-input',
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  created () {
  },
  methods: {
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    }
  }
}
</script>

<style lang="scss" scoped>
  .c-form-input {
    .desc {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>

<template>
  <div class="tree-setting" v-if="editTreeShow">
    <div class="edit-tree-box">
      <div class="edit-tree-head">
        <h1>添加内容</h1>
        <button class="edit-tree-head-close" @click="close">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <path d="M810.666667 273.066667L750.933333 213.333333 512 452.266667 273.066667 213.333333 213.333333 273.066667l238.933334 238.933333L213.333333 750.933333 273.066667 810.666667l238.933333-238.933334 238.933333 238.933334 59.733334-59.733334-238.933334-238.933333z">
            </path>
          </svg>
        </button>
      </div>
      <div class="edit-main custom-main">
        <c-form :config="treeSettingConfig" ref="treeSetting" :content="page_content" :formShow="true" v-if="editTreeShow" @submit="submit" :submitFlag.sync="submitSettingFlag" :delay="delay">
        </c-form>
      </div>
      <div class="edit-tree-footer" v-show="!treeSettingConfig.submitBtnShow">
        <div class="footer-btn">
          <el-button v-rights="'athena:design:edit'" type="primary" class="footer-btn__insert" @click="submitSetting">立即保存</el-button>
        </div>
      </div>
    </div>
    <div class="tree-backdrop" @click="close">
    </div>
  </div>
</template>

<script>
export default {
  name: 'tree-setting',
  props: {
    editTreeShow: {
      type: Boolean,
      default: false
    },
    treeSettingData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    treeSettingConfig: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      config: {
        components: []
      },
      page_content: {},
      copy_page_content: {},
      submitSettingFlag: false,
      delay: false,
      ownSubmitFlag: false
    }
  },
  created () {
  },
  methods: {
    submit (formData) {
      this.treeSettingData.label = formData.form[this.treeSettingConfig.components[0].id]
      this.treeSettingData.page_content = formData.form
      this.treeSettingData.medias = formData.medias
      if (this.treeSettingConfig.submitBtnShow || (this.submitSettingFlag && this.ownSubmitFlag)) {
        this.$emit('update:editTreeShow', false)
        this.$emit('submit')
        // 复原
        this.ownSubmitFlag = false
      }
    },
    close () {
      this.$emit('update:editTreeShow', false)
    },
    submitSetting () {
      this.ownSubmitFlag = true
      this.$refs['treeSetting'].submitForm('form')
    }
  },
  watch: {
    editTreeShow (val) {
      if (val) {
        this.$nextTick(() => {
          if (this.treeSettingData.page_content === false) {
            let obj = {}
            obj[this.treeSettingConfig.components[0].id] = Object.assign({}, this.treeSettingData).label
            this.page_content = obj
          } else {
            this.page_content = Object.assign({}, this.treeSettingData.page_content)
          }
        })
      } else {
        this.page_content = {}
      }
    }
  }
}
</script>
<style lang="scss">
  .tree-setting {
    .edit-tree-box {
      .edit-tree-head {
        .edit-tree-head-close {
          transition: fill 0.2s ease-in-out;
          svg {
            fill: #373d41;
          }
          &:hover {
            svg {
              fill: #409EFF;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .tree-setting {
    .edit-tree-box {
      position: fixed;
      width: 40%;
      min-width: 600px;
      top: 5%;
      left: 5%;
      right: 5%;
      bottom: 5%;
      z-index: 166;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 2px;
      .edit-tree-head {
        font-weight: 700;
        font-size: 12px;
        color: #373d41;
        text-align: left;
        border-bottom: 1px solid #e4e7ed;
        h1 {
          font-size: 24px;
          padding: 0 16px;
          line-height: 50px;
        }
        .edit-tree-head-close {
          border: 0;
          background: transparent;
          width: 50px;
          height: 50px;
          padding: 0;
          margin: 0;
          outline: 0;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .edit-main {
        padding: 10px 25px;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        max-height: calc(100% - 101px);
      }
      .edit-tree-footer {
        width: 100%;
        height: 50px;
        font-weight: 700;
        font-size: 12px;
        color: #373d41;
        line-height: 50px;
        box-sizing: border-box;
        padding: 0 16px;
        border-top: 1px solid #e4e7ed;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        .footer-btn {
          width: 180px;
          margin: 0 auto;
          .footer-btn__insert {
            padding: 10px 20px;
            width: 180px;
          }
        }
        &::after {
          content: '';
          display: block;
          height: 0;
          visibility: hidden;
          clear: both;
        }
      }
    }
    .tree-backdrop {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      min-height: 360px;
      background-color: #000;
      opacity: 0.7;
      z-index: 160;
    }
  }
.custom-main{
  .c-form{
    padding-bottom: 0;
    .el_real_form{
      max-height: 400px;
      overflow-y: auto;
    }
  }
}
</style>

<template>
  <el-dropdown placement="bottom-start" class="my_select" trigger="click" size="small" @command="handleCommand"
               @visible-change="visibleChange">
    <div class="show_box">
      <p :class="placeholder">{{label}}</p>
      <p class="select_text">{{showText}}</p>
      <input type="text" placeholder="输入进行搜索" class="search_area" v-if="isDropArea&&visible" @input="searchArea"
             @click.stop
             v-model="searchVal">
      <i v-if="clearable" @click.stop="clearSelect" class="iconfont icon-shanchu clear"
         v-show="this.showText.length!==0"></i>
      <i class="iconfont icon-xialaicon icon" :class="visible?'rotate':''"></i>
    </div>
    <el-dropdown-menu slot="dropdown" :class="isDropArea?'drop_area':'drop_normal'">
      <el-dropdown-item
        v-for="(item,index) in dropVal"
        :key="index"
        :command="item"
      >
        {{item.label}}
      </el-dropdown-item>
      <div class="null_data" v-if="dropVal.length === 0">无匹配结果</div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'

export default {
  name: 'SearchSelect',
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default () {
        return ''
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    label: {
      type: [String],
      default () {
        return ''
      }
    },
    clearable: {
      type: Boolean,
      default: true
    },
    // isDropArea的length依据数
    changeLength: {
      type: Number,
      default: 6
    }
  },
  data () {
    return {
      dropVal: [],
      showText: '',
      searchVal: '',
      visible: false,
      onFocus: false,
      isDropArea: false
    }
  },
  computed: {
    placeholder () {
      if (this.showText || this.onFocus) {
        return 'placeholder top'
      } else {
        return 'placeholder'
      }
    }
  },
  watch: {
    value (now, before) {
      this.checkSelect()
    },
    data (now, before) {
      this.dropVal = now
      this.checkSelect()
      if (now.length > this.changeLength) {
        this.isDropArea = true
      }
    }
  },
  mounted () {
    this.checkSelect()
    this.dropVal = this.data
    if (this.data.length > 6) {
      this.isDropArea = true
    }
  },
  methods: {
    // handleFocus (event) {
    //   this.onFocus = true
    // },
    handleBlur (event) {
      this.onFocus = false
      this.$emit('on-blur', event)
    },
    handleCommand (data) {
      this.showText = data.label
      this.$emit('change', data.value)
      this.$emit('input', data.value)
      this.$emit('chaToSearch')
    },
    visibleChange (value) {
      if (value) this.searchArea()
      this.onFocus = value
      if (!this.data.length && value) this.$emit('get-data')
      this.visible = value
      this.searchVal = ''
    },
    clearSelect () {
      this.onFocus = false
      this.$emit('change', '')
      this.$emit('input', '')
    },
    checkSelect () {
      let selectData = this.data.find(item => {
        if (item.value === this.value) return true
      })
      if (selectData) {
        this.showText = selectData.label
      } else {
        this.showText = ''
      }
    },
    searchArea () {
      this.dropVal = this.data.filter(item => {
        return item.label.indexOf(this.searchVal) !== -1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.my_select {
  float: left;
  width: 180px;
  height: 40px;
  margin: 10px 0 20px 20px;
  vertical-align: top;
  cursor: pointer;
}
.drop_normal {
  width: 180px;
  max-height: 200px;
  overflow: auto
}
.drop_area {
  width: 560px;
  max-height: 200px;
  overflow: auto;
  li {
    float: left;
    width: 135px;
    cursor: pointer;
  }
}
.show_box {
  position: relative;
  width: 180px;
  height: 40px;
  border-bottom: 1px solid #C3C6D6;
  .placeholder {
    position: relative;
    top: 20px;
    color: #878A92;
    font-size: 12px;
    line-height: 14px;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
  }
  .top {
    top: 0;
    color: #878a92;
  }
  .select_text {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 7px;
    color: #4B4F58;
  }
  .search_area {
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 4px;
    color: #4B4F58;
    border: 0;
  }
  .clear {
    display: none;
    position: absolute;
    top: 20px;
    right: 28px;
    color: #C3C6D6;
  }
  &:hover {
    .clear {
      display: block;
    }
  }
  .icon {
    position: absolute;
    top: 18px;
    right: 8px;
    font-size: 14px;
    color: #C3C6D6;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .rotate {
    transform: rotate(180deg)
  }
}
.null_data {
  text-align: center;
  height: 50px;
  margin-top: 25px;
}
</style>

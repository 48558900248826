<template>
  <div class="c-tree">
    <el-form-item v-if="formItem.type === 'tree-general'" :label="handleLabel(formItem)" :prop="formItem.id"
                  :rules="filter_rules(formItem.rules)">
      <div class="tree-box">
        <el-button type="text" size="mini" @click="appendFirst()">
          {{ formItem.btnText !== undefined ? formItem.btnText : '添加一级菜单' }}
        </el-button>
        <el-tree :data="treeData" node-key="cid" accordion draggable @node-expand="nodeExpand"
                 :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false">
          <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
           <span>
             <el-button type="text" size="mini" @click="append(data)">添加</el-button>
             <el-button type="text" size="mini" style="color: #F56C6C;" @click="remove(node, data)">删除</el-button>
             <el-button type="text" size="mini" @click="edit(node, data)">编辑</el-button>
           </span>
         </span>
        </el-tree>
      </div>
    </el-form-item>
    <TreeSetting :editTreeShow.sync="editTreeShow" :treeSettingData="treeSettingData"
                 :treeSettingConfig="formItem.config" @submit="submit"></TreeSetting>
  </div>
</template>

<script>
import TreeSetting from './setting/index'

export default {
  name: 'c-tree',
  components: {
    TreeSetting
  },
  props: {
    formField: {
      type: Array,
      default: () => {
        return []
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      treeData: [],
      sid: 10000,
      editTreeShow: false,
      treeSettingData: {},
      arrIndex: [],
      restoreData: {},
      expandNode: [],
      defaultExpandKeys: []
    }
  },
  created () {
    if (this.formField !== null) {
      this.initData(this.formField)
    }
  },
  methods: {
    initData (data) {
      this.arrIndex = []
      data.forEach((item, indx) => {
        this.arrIndex.push(item.cid)
        if (item.children.length > 0) {
          this.handleLoop(item.children)
        }
        if (data.length - 1 === indx) {
          this.arrIndex.sort((a, b) => {
            return a - b
          })
          this.sid = this.arrIndex[this.arrIndex.length - 1] + 1
        }
      })
    },
    // 死循环
    handleLoop (data) {
      for (let i = 0; i < data.length; i++) {
        this.arrIndex.push(data[i].cid)
        if (data[i].children.length > 0) {
          this.handleLoop(data[i].children)
        } else {
          continue
        }
        if (data.length - 1 === i) {
          break
        }
      }
    },
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    append (data) {
      let newChild = { cid: this.sid++, label: '名称', page_content: false, medias: [], children: [] }
      if (!data.children) {
        this.$set(data, 'children', [])
      }
      data.children.push(newChild)
      this.$emit('update:formField', this.treeData)
    },
    appendFirst () {
      let newChild = { cid: this.sid++, label: '名称', page_content: false, medias: [], children: [] }
      this.treeData.push(newChild)
      this.$emit('update:formField', this.treeData)
    },
    remove (node, data) {
      const self = this
      this.$antConfirm({
        title: '删除菜单',
        content: '删除后，菜单及其数据均被删除，无法恢复。\n 是否确定删除此菜单？',
        width: '500px',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          let parent = node.parent
          let children = parent.data.children || parent.data
          let index = children.findIndex(d => d.cid === data.cid)
          children.splice(index, 1)
          self.$emit('update:formField', self.treeData)
          self.$emit('submit')
          self.$message({
            type: 'success',
            message: '删除成功!'
          })
        },
        onCancel() {}
      })
    },
    edit (node, data) {
      this.editTreeShow = true
      this.treeSettingData = data
    },
    submit () {
      this.$emit('submit')
    },
    nodeExpand (data, node) {
      this.expandNode.push(node)
    }
  },
  watch: {
    formField (val) {
      if (val !== null && val.length > 0) {
        this.defaultExpandKeys = this.expandNode.map(item => item.expanded && item.data.cid).filter(id => id)
        this.treeData = val
        this.initData(val)
      }
    }
  }
}
</script>
<style lang="scss">
.c-tree {
  .el-tree-node__content {
    height: 40px;
  }
  .el-tree-node__expand-icon {
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped>
.c-tree {
  .tree-box {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    outline: none;
    position: relative;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-right: 8px;
    width: calc(100% - 35px);
    span {
      &:first-child {
        font-size: 16px;
        width: calc(100% - 120px);
        overflow: hidden;
        text-align: left;
      }
      &:last-child {
        width: 100px;
        overflow: hidden;
      }
    }
  }
}
</style>

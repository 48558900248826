<template>
  <div class="topic-dom">
    <span class="title">{{names[names.length - 1]}}</span>
    <slot></slot>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'PageTopic',
  computed: {
    names () {
      let arr = ['云影接单'].concat(this.$store.state.curBreadcrumb)
      return arr
    }
  }
}
</script>

<style scoped lang="scss">
.topic-dom {
  height: 70px !important;
  margin-right: -20px;
  margin-left: -20px;
  background-color: #fff;
  box-shadow: 0 -1px 0 0 #EAEBF0 inset;
  .title {
    display: inline-block;
    position: relative;
    font-size: 18px;
    line-height: 25px;
    margin: 23px auto 22px 34px;
    font-weight: 600;
    &::before {
      position: absolute;
      left: -14px;
      top: 50%;
      content: '';
      display: block;
      width: 4px;
      height: 18px;
      margin-top: -9px;
      background-color: #3083F2;
    }
  }
  .btn-group {
    float: right;
    margin: 21px 20px 21px 0;
  }
}
</style>

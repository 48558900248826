<template>
  <search-drop-form :placeholder="label" :has-value="currentValue || isFocus">
    <el-date-picker
      v-model="currentValue"
      :clearable="isShowClear"
      :align="align"
      prefix-icon="iconfont icon-rili"
      :type="dateType"
      :value-format="valueFormat"
      @focus="handleFocus"
      @blur="handleBlur()"
      :picker-options="pickerOptions0">
    </el-date-picker>
  </search-drop-form>
</template>

<script>
import SearchDropForm from '@/components/SearchDropForm'
import { FChangeDate } from '@/utils/common'

export default {
  name: 'SearchTimePicker',
  components: {
    'search-drop-form': SearchDropForm
  },
  props: {
    value: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    label: {
      type: [String]
    },
    valueFormat: { // 日期时间格式
      type: [String],
      default () {
        return 'yyyy-MM-dd HH:mm:ss'
      }
    },
    dateType: { // 显示类型：year/month/date/week/datetime/datetimerange/daterange
      type: [String],
      default () {
        return 'datetime'
      }
    },
    earlyNowDisable: { // 未来时间是否置灰
      type: [Boolean],
      default () {
        return true
      }
    },
    laterTimeDisable: { // 只能选择更早的时间（该时间之后置灰）
      type: [String],
      default () {
        return ''
      }
    },
    earlyTimeDisable: { // 只能选择更晚的时间（该时间之后置灰）
      type: [String],
      default () {
        return ''
      }
    },
    dayRange: {
      type: [Number],
      default () {
        return -1
      }
    },
    isShowClear: { // 是否显示删除icon
      type: [Boolean],
      default () {
        return true
      }
    },
    align: { // 对齐方式（left, center, right）
      type: [String],
      default () {
        return 'left'
      }
    }
  },
  data () {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          let rangeLaterTime, rangeEarlyTime
          if (this.dayRange && this.dayRange !== -1 && this.laterTimeDisable) {
            rangeLaterTime = FChangeDate(new Date(+new Date(this.laterTimeDisable) - 86400000 * this.dayRange))
          }
          if (this.dayRange && this.dayRange !== -1 && this.earlyTimeDisable) {
            rangeEarlyTime = FChangeDate(new Date(+new Date(this.earlyTimeDisable) + 86400000 * this.dayRange))
          }
          if (this.earlyNowDisable) {
            if ((this.laterTimeDisable !== '' && this.laterTimeDisable !== null) || (this.earlyTimeDisable !== '' && this.earlyTimeDisable !== null)) {
              return time.getTime() > Date.now() || time > new Date(this.laterTimeDisable) ||
                     time < new Date(this.earlyTimeDisable) || time < new Date(rangeLaterTime) ||
                     time > new Date(rangeEarlyTime)
            } else {
              return time.getTime() > Date.now() || time < new Date(rangeLaterTime) ||
                     time > new Date(rangeEarlyTime)
            }
          } else {
            if ((this.laterTimeDisable !== '' && this.laterTimeDisable !== null) || (this.earlyTimeDisable !== '' && this.earlyTimeDisable !== null)) {
              return time > new Date(this.laterTimeDisable) || time < new Date(this.earlyTimeDisable) ||
                     time < new Date(rangeLaterTime) || time > new Date(rangeEarlyTime)
            }
          }
        }
      },
      currentValue: '',
      isFocus: false
    }
  },
  watch: {
    value (val) {
      this.setCurrentValue(val)
    },
    currentValue (val) {
      if (this.value !== val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  mounted () {
    this.setCurrentValue(this.value)
  },
  methods: {
    setCurrentValue (value) {
      if (value === this.currentValue) return
      this.currentValue = value
    },
    handleFocus (key) {
      this.isFocus = true
    },
    // 失去焦点时校验
    handleBlur () {
      this.isFocus = false
      if (this.laterTimeDisable && new Date(this.currentValue).getTime() !== 0 && new Date(this.currentValue).getTime() > new Date(this.laterTimeDisable).getTime()) {
        this.currentValue = this.laterTimeDisable
      }
      if (this.earlyTimeDisable && new Date(this.currentValue).getTime() !== 0 && new Date(this.currentValue).getTime() < new Date(this.earlyTimeDisable).getTime()) {
        this.currentValue = this.earlyTimeDisable
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

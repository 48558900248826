'use strict'

// 检查数据空,默认 return false
let checkValue = (value) => {
  if (value === undefined) {
    return true
  }
  if (value === null) {
    return true
  }
  if (value === 'null') {
    return true
  }
  if (value === false) {
    return true
  }
  if (value === '') {
    return true
  }
  return false
}
// 时间戳 转 yyyy-mm-dd h:m:s
let formatDateTime = (time) => {
  let date = new Date(time)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  let d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  let h = date.getHours()
  h = h < 10 ? ('0' + h) : h
  let min = date.getMinutes()
  let second = date.getSeconds()
  min = min < 10 ? ('0' + min) : min
  second = second < 10 ? ('0' + second) : second
  return y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + second
}
// 时间字符串转时间戳
let formatDateStr = (str) => {
  let date = str
  date = date.substring(0, 19)
  date = date.replace(/-/g, '/')
  let timestamp = new Date(date).getTime()
  return timestamp
}
export default {
  checkValue,
  formatDateTime,
  formatDateStr
}

import Vue from 'vue'
import PageTopic from '../components/PageTopic'
import Pagination from '../components/Pagination'
import SearchSelect from '../components/SearchSelect'
import SearchInput from '../components/SearchInput'
import SearchTimePicker from '../components/SearchTimePicker'
import SearchDrop from '../components/SearchDrop'
import MyForm from '../components/c-form'
import Medias from '@/components/vue-media-library'

import {
  Input,
  Button,
  Table,
  TableColumn,
  Loading,
  Container,
  Header,
  Main,
  Footer,
  Message,
  MessageBox,
  Col,
  DatePicker,
  Dialog,
  Form,
  FormItem,
  Select,
  Option,
  Tabs,
  TabPane,
  RadioGroup,
  Radio,
  RadioButton,
  Tree,
  ColorPicker
} from 'element-ui'

Dialog.props.closeOnClickModal.default = false

Vue.component('c-form', MyForm)
Vue.component('PageTopic', PageTopic)
Vue.component('Pagination', Pagination)
Vue.component('SearchSelect', SearchSelect)
Vue.component('SearchInput', SearchInput)
Vue.component('SearchTimePicker', SearchTimePicker)
Vue.component('SearchDrop', SearchDrop)
Vue.component('Medias', Medias)
Vue.use(Input)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Radio)
Vue.use(Tree)
Vue.use(ColorPicker)
Vue.use(Loading.directive)

Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt

// 校验特殊字符或者空格
let validateName = (rule, value, callback) => {
  // let regx = /[%&\\',\s;=?$]+/ 含有空格
  let regx = /[%&\\',;=?$]+/
  if (regx.test(value)) {
    callback(new Error('名称不能含有特殊字符或者空格！'))
  }
  callback()
}
// 校验图片
let validateImage = (rule, value, callback) => {
  if (value) {
    if (JSON.stringify(value).indexOf('type') !== -1 && value.type !== 'image') {
      callback(new Error('该项只能为图片！'))
    }
  }
  callback()
}
// 校验视频
let validateVideo = (rule, value, callback) => {
  if (value) {
    if (JSON.stringify(value).indexOf('type') !== -1 && value.type !== 'video') {
      callback(new Error('该项只能为视频！'))
    }
  }
  callback()
}

Vue.prototype.filter_rules = (item) => {
  let rules = []
  if (item === undefined) {
    return rules.push({})
  }
  if (item.required) {
    rules.push({
      required: true,
      message: '该输入项为必填项！',
      trigger: 'blur'
    })
  }
  if (item.maxLength) {
    rules.push({
      min: 1,
      max: item.maxLength,
      message: '最多输入' + item.maxLength + '个字符！',
      trigger: 'blur'
    })
  }
  if (item.min && item.max) {
    rules.push({
      min: item.min,
      max: item.max,
      message: '字符长度在' + item.min + '至' + item.max + '之间！',
      trigger: 'blur'
    })
  }
  if (item.type) {
    let type = item.type
    switch (type) {
      case 'email':
        rules.push({
          type: 'email',
          message: '请输入正确的邮箱地址！',
          trigger: 'blur, change'
        })
        break
      case 'categoryName':
        rules.push({
          validator: validateName,
          trigger: 'blur'
        })
        break
      case 'validateImage':
        rules.push({
          validator: validateImage,
          trigger: 'change'
        })
        break
      case 'validateVideo':
        rules.push({
          validator: validateVideo,
          trigger: 'change'
        })
        break
      default:
        rules.push({})
        break
    }
  }
  return rules
}

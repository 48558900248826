<template>
  <div id="media-head">
    <h1>
      添加媒体</h1>
    <button class="media-head-close" @click="close">
      <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path d="M810.666667 273.066667L750.933333 213.333333 512 452.266667 273.066667 213.333333 213.333333 273.066667l238.933334 238.933333L213.333333 750.933333 273.066667 810.666667l238.933333-238.933334 238.933333 238.933334 59.733334-59.733334-238.933334-238.933333z">
        </path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'media-head',
  props: {
  },
  data () {
    return {}
  },
  created () {
  },
  methods: {
    // 点击 关闭媒体库
    close () {
      this.$emit('mediasClose', false)
    }
  }
}
</script>
<style lang="scss">
  #media-head {
    .media-head-close {
      transition: fill 0.2s ease-in-out;
      svg {
        fill: #373d41;
      }
      &:hover {
        svg {
          fill: #409EFF;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  #media-head {
    font-weight: 700;
    font-size: 12px;
    color: #373d41;
    text-align: left;
    h1 {
      font-size: 24px;
      padding: 0 16px;
      line-height: 50px;
    }
    .media-head-close {
      border: 0;
      background: transparent;
      width: 50px;
      height: 50px;
      padding: 0;
      margin: 0;
      outline: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
</style>

<template>
  <transition name="fade">
    <div id="medias-lib" v-show="mediasShow">
      <div class="media-modal" ref="select_upload">
        <!--header-->
        <MediaHead @mediasClose="mediasCloseFn" />
        <!--filter-->
        <MediaFilter :filterBtnDisabled="filterBtnDisabled" :prfilterType.sync="prfilterType"
                     @filterQueryMedia="filterQueryMedia" />
        <!--主体 main-->
        <div class="media-wrapper">
          <FolderTree @change="folderChange" />
          <div class="media_box">
            <Main
              :mediaArr.sync="showMediaArr"
              :activeMediasArrLib.sync="activeMediasArr"
              :activeStartIndexLib.sync="activeStartIndex"
              @handleScroll="handleScroll"
              :mainEventScroll="mainEventScroll"
              :mediaMultiple="mediaMultiple"
              :selection="selection"
              :loading="loading"
              loadingContent="数据加载中"
              :loadingCls="loadingCls"
              ref="main_box"
            />
          </div>
        </div>
        <!--footer-->
        <MediaFooter @mediasClose="mediasDataFn" :activeMediasArr="activeMediasArr" :medias-show="mediasShow"
                     :uploadingDisabledBtn="uploadingDisabledBtn"></MediaFooter>
      </div>
      <div class="media-modal-backdrop" @click.stop="mediasCloseFn(false)">
      </div>
      <Upload ref="upload" @progress="onProgress"></Upload>
    </div>
  </transition>
</template>

<script>
import MediaHead from './head'
import MediaFilter from './filter'
import Main from './main'
import MediaFooter from './footer'
import FolderTree from './FolderTree'
import Upload from '@/components/Upload'

export default {
  name: 'medias-lib',
  components: {
    MediaHead,
    Main,
    MediaFooter,
    MediaFilter,
    FolderTree,
    Upload
  },
  props: {
    mediasShow: {
      type: Boolean,
      default: true
    },
    mediaMultiple: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Object,
      default: () => {
        return {
          selectType: 'multiple',
          number: 200
        }
      }
    },
    externalFiles: [FileList, Object, Array]
  },
  data () {
    return {
      /*
      * file:
      *  check 选中状态
      *  status 上传状态
      */
      mediaArr: [],
      activeMediasArr: [],
      fileList: [],
      // 限制图片和视频大小 ,单位 M 兆
      activeStartIndex: false,
      // 滚动条
      scroll: {},
      // 滚动加载顺序
      forbidScroll: false,
      // 媒体库查询参数
      mediaQueryCondition: {
        type: 'all',
        page: 1,
        size: 25
      },
      mainEventScroll: false,
      prfilterType: '全部',
      filterBtnDisabled: false,
      // 正在上传,禁止插入文章的开关
      uploadingDisabledBtn: false,
      loading: false,
      loadingCls: '',
      uploadingList: []
    }
  },
  computed: {
    showMediaArr () {
      const upload = this.uploadingList
      return [...upload, ...this.mediaArr]
    }
  },
  methods: {
    openUpload () {
      this.$refs.upload.show(this.mediaQueryCondition.folderId)
    },
    onProgress (data) {
      let allFinish = true
      this.uploadingList = data.map(item => {
        if (item.status === 'uploading' || item.status === 'ready') {
          allFinish = false
        }
        if (item.response && item.response.data) {
          return { ...item.response.data, check: true, status: 'finish' }
        } else {
          return { ...item, id: item.uid }
        }
      })
      if (allFinish) {
        this.$message.success('上传完成')
        this.activeMediasArr = this.uploadingList
        this.mediaArr = this.mediaArr.map(item => {
          return { ...item, check: false }
        })
        this.mediaArr = [...this.uploadingList, ...this.mediaArr]
        this.uploadingList = []
        this.$forceUpdate()
      }
    },
    // 媒体库是否关闭
    mediasCloseFn (data) {
      this.$emit('update:mediasShow', data)
      this.prfilterType = 'reset'
      this.mediaArr = []
    },
    // footer 插入文章
    mediasDataFn (data) {
      this.$emit('update:mediasShow', data)
      // 判断是否有error的,有去除
      this.activeMediasArr.forEach((item, index) => {
        if (item.status === 'error') {
          this.activeMediasArr.splice(index, 1)
        }
      })
      this.$emit('update:dataFiles', this.activeMediasArr)
      // 清空当前选中的media 和 置false
      this.activeMediasArr = []
      this.mediaArr.forEach((item) => {
        item.check = false
      })
      this.prfilterType = 'reset'
      this.mediaArr = []
    },
    folderChange ({ folderId }) {
      this.mediaQueryCondition.folderId = folderId
      this.handleInitQueryMedia()
    },
    /*
    * 初始 加载媒体库
    * @method handleQueryMedia
    * @params {}
    */
    handleInitQueryMedia () {
      this.mediaQueryCondition.page = 1
      this.loadingCls = ''
      this.loading = true
      this.$axios.get('/media/media/list', this.mediaQueryCondition).then(res => {
        this.loading = false
        if (res.code === '0000') {
          let files = res.data.list
          files.forEach(item => {
            item['check'] = false
            item['status'] = 'finish'
          })
          this.mediaArr = files
          this.forbidScroll = true
        }
      })
    },
    /*
    * 监听滚动条 加载媒体库数据
    * @method handleScroll
    * @params {Number} data 滚动参数
    */
    handleScroll (data) {
      this.scroll = data
      if (this.scroll.scrollTop + this.scroll.clientHeight + 2 >= this.scroll.scrollHeight) {
        if (this.forbidScroll) {
          this.forbidScroll = false
          this.loading = true
          this.mediaQueryCondition.page = this.mediaQueryCondition.page + 1
          this.$axios.get('/media/media/list', this.mediaQueryCondition).then(res => {
            this.loading = false
            if (res.code === '0000') {
              let files = res.data.list
              this.forbidScroll = res.data.hasNextPage
              if (files.length > 0) {
                files.forEach(item => {
                  item['check'] = false
                  item['status'] = 'finish'
                })
              }
            }
          })
        }
      }
    },
    /*
    * 筛选事件 加载媒体库数据
    * @method filterQueryMedia
    * @params {String} data 类型
    */
    filterQueryMedia (data) {
      if (this.mediasShow === false) {
        this.mediaQueryCondition.type = data
        this.mediaQueryCondition.page = 1
        return
      }
      this.loading = true
      this.mediaQueryCondition.type = data
      this.mediaQueryCondition.page = 1
      this.mainEventScroll = true
      this.$refs.main_box.$refs.browse_scroll.scrollTo(0, 0)
      this.$axios.get('/media/media/list', this.mediaQueryCondition).then(res => {
        this.loading = false
        if (res.code === '0000') {
          let files = res.data.list
          this.mediaArr = []
          if (files.length > 0) {
            files.forEach((item, index) => {
              item['check'] = false
              item['status'] = 'finish'
              this.mediaArr.push(item)
              if (index === files.length - 1) {
                this.forbidScroll = true
                this.mainEventScroll = false
              }
            })
          }
        }
      })
    }
  },
  watch: {
    mediasShow (val) {
      if (val) {
        this.handleInitQueryMedia()
      }
    },
    // 外部传入媒体资源
    externalFiles (val) {
      if (val != null && val.length > 0) {
        this.prfilterType = '全部'
        this.filterBtnDisabled = true
        this.uploadingDisabledBtn = true
        this.loading = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#medias-lib {
  user-select: none;
  position: relative;
  z-index: 100;
  .media-modal {
    position: fixed;
    width: 90%;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    z-index: 100;
    background-color: #fff;
    border-radius: 2px;
    .medias-loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 3;

    }
    #media-filter {
      justify-content: flex-end;
    }
  }
  .media-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 360px;
    background-color: #000;
    opacity: .7;
    z-index: 99;
  }
  .media-upload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 360px;
    background-color: #196790;
    opacity: 0.7;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .media-upload-line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 96%;
      height: 96%;
      border: 1px dashed #fff;
      transform: translate(-50%, -50%);

    }
    p {
      font-size: 40px;
      color: #fff;
    }
  }
}

.media-wrapper {
  padding: 0 0 0 16px;
  height: calc(100% - 100px);
  .tree_box {
    float: left;
    width: 180px;
    height: 100%;
    overflow: auto;
    border-right: 1px solid #ddd;
  }
  .media_box {
    float: left;
    width: calc(100% - 180px);
    height: 100%;
    position: relative;
  }
}
</style>

<template>
  <div class="search_bar clearfix" :class="{'hidden': !showExpend}">
    <div class="search_bar_top">
      <span class="search_label">筛选条件：</span>
      <div class="search_form_group clearfix" ref="groups">
        <slot></slot>
      </div>
      <div class="right_btn_group">
        <span @click="fReset" class="btn">
          <i class="iconfont icon-zhongzhiicon"></i>重置
        </span>
        <span @click="fSearch" class="btn">
          <i class="iconfont icon-sousuoda"></i>查询
        </span>
        <span
          v-if="showMoreBtn"
          class="more_btn"
          :class="{'act': showExpend}"
          @click="fToggleExpend"
        >更多<i class="icon_arrow"></i></span>
      </div>
    </div>
    <span class="search_label_more">更多筛选：</span>
  </div>
</template>

<script>
export default {
  name: 'SearchDrop',
  data () {
    return {
      showExpend: false,
      showMoreBtn: false
    }
  },
  mounted () {
    this.checkBarHeight()
    window.addEventListener('resize', this.checkBarHeight)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkBarHeight)
  },
  methods: {
    checkBarHeight () {
      let barHeight = this.$refs.groups.offsetHeight
      if (barHeight > 72) {
        this.showMoreBtn = true
      } else {
        this.showMoreBtn = false
      }
    },
    fToggleExpend () {
      this.showExpend = !this.showExpend
    },
    fSearch () {
      this.$emit('on-search')
    },
    fReset () {
      this.$emit('on-reset')
    }
  }
}
</script>
<style scoped lang="scss">
.search_bar {
  position: relative;
  background-color: #F5F5F5;
  box-shadow: 0 -1px 0 0 #C3C6D6 inset;
  &.hidden {
    height: 70px;
    overflow: hidden;
  }
}
.search_bar_top {
  position: relative;
  height: inherit;
  padding-right: 240px;
  background-color: #fff;
  box-shadow: 0 -1px 0 0 #EAEBF0 inset;
}
.search_form_group {
  margin-left: 90px;
}
.btn {
  display: inline-block;
  width: 70px;
  height: 30px;
  margin-left: 5px;
  color: #878A92;
  text-align: center;
  line-height: 30px;
  border: 1px solid #C3C6D6;
  border-radius: 4px;
  cursor: pointer;
  .iconfont {
    margin-right: 5px;
  }
  &:active {
    background-color: #efefef;
  }
}
.right_btn_group {
  direction: rtl;
  position: absolute;
  right: 20px;
  top: 20px;
  bottom: 0;
  width: 240px;
}
.more_btn {
  position: relative;
  display: inline-block;
  color: #3083F2;
  font-size: 14px;
  line-height: 20px;
  padding-right: 32px;
  margin-left: 14px;
  cursor: pointer;
  .icon_arrow {
    position: absolute;
    right: 16px;
    top: 8px;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 5px solid #3083F2;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: all 0.5s;
    transform-origin: center;
  }
  .icon_arrow {
    transform: rotate(180deg)
  }
  &.act .icon_arrow {
    transform: rotate(0deg)
  }
}
.search_label {
  float: left;
  margin: 25px 0 25px 20px;
  color: #4B4F58;
  font-size: 14px;
  line-height: 20px;
}
.search_label_more {
  position: absolute;
  left: 20px;
  top: 95px;
  color: #878A92;
  font-size: 14px;
  line-height: 20px;
}
</style>

<template>
  <div class="my_input">
    <span v-text="label" :class="mySpanClass"></span>
    <input
      class="input_tag"
      :value="currentValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
      @input="handleInput"
      @keyup.enter="onEnter"
      :readonly="readonly"
      type="text">
    <i @click.stop="deleteSelect" class="iconfont icon-shanchu close_icon" v-show="this.currentValue.length!==0"></i>
    <i class="iconfont icon-shaixuantiaojian-shurukuang write"></i>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: [String, Number],
      default () {
        return ''
      }
    },
    label: {
      type: [String]
    },
    readonly: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      currentValue: '',
      onFocus: false,
      isShow: false
    }
  },
  computed: {
    mySpanClass () {
      if (this.onFocus || this.currentValue) {
        return 'to_top span_tag'
      } else {
        return 'span_tag'
      }
    }
  },
  watch: {
    value (val) {
      this.setCurrentValue(val)
    }
  },
  mounted () {
    this.setCurrentValue(this.value)
  },
  methods: {
    onEnter () {
      this.$emit('enter')
    },
    deleteSelect () {
      this.currentValue = ''
      this.$emit('input', '')
    },
    handleFocus (event) {
      this.onFocus = true
      this.$emit('on-focus', event)
    },
    handleBlur (event) {
      this.onFocus = false
      this.$emit('on-blur', event)
    },
    handleInput (event) {
      let value = event.target.value
      this.$emit('input', value)
      this.setCurrentValue(value)
      this.$emit('on-change', event)
    },
    setCurrentValue (value) {
      if (value === this.currentValue) return
      this.currentValue = value
    },
    handleChange (event) {
      this.$emit('on-input-change', event)
    }
  }
}
</script>
<style lang="scss" scoped>
.my_input {
  float: left;
  width: 180px;
  height: 40px;
  margin: 10px 0 20px 20px;
  position: relative;
  .write {
    position: absolute;
    right: 5px;
    color: #C3C6D6;
  }
  .close_icon {
    display: none;
    z-index: 2;
    position: absolute;
    right: 25px;
    color: #C3C6D6;
    cursor: pointer;
  }
  &:hover {
    .close_icon {
      display: inline-block;
    }
  }
}
.span_tag {
  color: #878A92;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  z-index: 0;
  top: 18px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.to_top {
  top: 0px;
  color: #878a92;
}
.input_tag {
  position: relative;
  top: 1px;
  color: #4B4F58;
  z-index: 1;
  background: none;
  padding-right: 42px;
  width: 100%;
  height: 22px;
  font-size: 12px;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #C3C6D6;
}

</style>

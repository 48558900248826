/**
 * @Description:
 * @author Guan LinTao
 * @date 8:22 下午
 * 统一暴露getters
 */

export default {
  equipInfo: state => state.userStore.equipInfo,
  userMenu: state => state.userStore.userMenu,
  cityData: state => state.baseConfigStore.cityData,
  appInfo: state => state.userStore.appInfo,
  baseAppInfo: state => state.userStore.baseAppInfo,
  userRights: state => state.userStore.userRights,
  routeRightsMap: state => state.permissionStore.routeRightsMap
}

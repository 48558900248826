<template>
  <div>
    <div class="c-form" v-if="!$CheckValue(config) && formShow">
      <el-form :model="form" ref="form" @submit.native.prevent class="el_real_form">
        <ul class="form_ul">
          <li v-for="(formItem, index) in config.components" :key="index">
            <transition name="fade">
              <c-input :formItem="formItem" :form="form" v-if="formItem.type === 'input-text'"></c-input>
              <c-textarea :formItem="formItem" :form="form" v-if="formItem.type === 'textarea'"></c-textarea>
              <c-image :formItem="formItem" :formField.sync="form[formItem.id]" v-if="formItem.type === 'image'"
                       @submit="formItem.autoSubmit && submitForm('form')"></c-image>
              <c-video :formItem="formItem" :formField.sync="form[formItem.id]" v-else-if="formItem.type === 'video'"
                       @submit="formItem.autoSubmit && submitForm('form')"></c-video>
              <c-medias :formItem="formItem" :formField.sync="form[formItem.id]" v-else-if="formItem.type === 'medias'"
                        @submit="formItem.autoSubmit && submitForm('form')"></c-medias>
              <c-tree-filter :formItem="formItem" :formField.sync="form[formItem.id]"
                             v-else-if="formItem.type === 'tree-filter-radio'"></c-tree-filter>
              <c-tree :formItem="formItem" :formField.sync="form[formItem.id]"
                      v-else-if="formItem.type === 'tree-general'"
                      @submit="formItem.autoSubmit && submitForm('form')"></c-tree>
              <c-radio :formItem="formItem" :formField.sync="form[formItem.id]" v-else-if="formItem.type === 'radio'"
                       @submit="formItem.autoSubmit && submitForm('form')"></c-radio>
              <c-radio-group ref="radioGroup" :ownCheck.sync="radioGroupFlag" :formItem="formItem"
                             :formField.sync="form[formItem.id]" v-else-if="formItem.type === 'radio-group'"
                             @submit="formItem.autoSubmit && submitForm('form')"></c-radio-group>
              <c-color-picker :formItem="formItem" :formField.sync="form[formItem.id]"
                              v-else-if="formItem.type === 'color-picker'"
                              @submit="formItem.autoSubmit && submitForm('form')"></c-color-picker>
              <c-checkbox :formItem="formItem" :formField.sync="form[formItem.id]"
                          v-else-if="formItem.type === 'checkbox'"
                          @submit="formItem.autoSubmit && submitForm('form')"></c-checkbox>
            </transition>
          </li>
        </ul>
      </el-form>
    </div>
    <div v-if="submitBtn" class="submit_box">
      <el-button v-rights="'athena:design:edit'" class="button" type="primary" @click="submitForm('form')" size="medium">立即保存</el-button>
    </div>
  </div>
</template>

<script>
import CInput from './input'
import CTextarea from './textarea'
import CImage from './image'
import CVideo from './video'
import CMedias from './medias'
import CTree from './tree/index'
import CTreeFilter from './tree/treeFilter/index'
import CRadio from './radio'
import CRadioGroup from './radioGroup'
import CCheckbox from './checkbox'
import CColorPicker from './colorPicker'

export default {
  name: 'c-form',
  components: {
    CInput,
    CImage,
    CVideo,
    CMedias,
    CTree,
    CTreeFilter,
    CRadio,
    CRadioGroup,
    CCheckbox,
    CTextarea,
    CColorPicker
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    content: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formShow: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      form: {},
      arr: [],
      radioGroupFlag: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.form = JSON.parse(JSON.stringify(this.content))
    })
  },
  computed: {
    submitBtn () {
      if (!this.$CheckValue(this.config.submitBtnShow)) {
        return this.config.submitBtnShow
      } else {
        return false
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (JSON.stringify(this.config.components).indexOf('radio-group') !== -1) {
            this.$refs['radioGroup'].forEach(item => {
              item.submitForm()
            })
          }
          if (this.delay) {
            setTimeout(() => {
              if (this.radioGroupFlag) {
                let formData = {
                  form: {},
                  medias: []
                }
                this.arr = []
                this.config.components.forEach(item => {
                  formData.form[item.id] = this.form[item.id] === undefined ? null : this.form[item.id]
                  if (formData.form[item.id] === null) {
                    return false
                  } else if (typeof formData.form[item.id] === 'object') {
                    if (formData.form[item.id].length > 0) {
                      formData.form[item.id].forEach(formItem => {
                        if (!this.$CheckValue(formItem.medias)) {
                          this.arr = this.arr.concat(formItem.medias)
                          if (!this.$CheckValue(formItem.children)) {
                            this.handleLoop(formItem.children)
                          }
                        } else if (!this.$CheckValue(formItem.id)) {
                          if (formItem.id !== formItem.page_id) {
                            this.arr.push(formItem.id)
                          }
                        }
                      })
                    } else {
                      if (formData.form[item.id].id !== formData.form[item.id].page_id) {
                        this.arr.push(formData.form[item.id].id)
                      }
                    }
                  }
                })
                formData.medias = this.arr
                // 临时,仅限直接用$refs操作此函数
                this.$emit('update:submitFlag', true)
                this.$emit('submit', formData)
              }
            }, 300)
          } else {
            if (this.radioGroupFlag) {
              let formData = {
                form: {},
                medias: []
              }
              this.arr = []
              this.config.components.forEach(item => {
                formData.form[item.id] = this.form[item.id] === undefined ? null : this.form[item.id]
                if (formData.form[item.id] === null) {
                  return false
                } else if (typeof formData.form[item.id] === 'object') {
                  if (formData.form[item.id].length > 0) {
                    formData.form[item.id].forEach(formItem => {
                      if (!this.$CheckValue(formItem.medias)) {
                        this.arr = this.arr.concat(formItem.medias)
                        if (!this.$CheckValue(formItem.children)) {
                          this.handleLoop(formItem.children)
                        }
                      } else if (!this.$CheckValue(formItem.id)) {
                        if (formItem.id !== formItem.page_id) {
                          this.arr.push(formItem.id)
                        }
                      }
                    })
                  } else {
                    if (formData.form[item.id].id !== formData.form[item.id].page_id) {
                      this.arr.push(formData.form[item.id].id)
                    }
                  }
                }
              })
              formData.medias = this.arr
              // 临时,仅限直接用$refs操作此函数
              this.$emit('update:submitFlag', true)
              this.$emit('submit', formData)
            }
          }
        } else {
          this.$emit('update:submitFlag', false)
          return false
        }
      })
    },
    handleLoop (data) {
      for (let i = 0; i < data.length; i++) {
        this.arr = this.arr.concat(data[i].medias)
        if (data[i].children.length > 0) {
          this.handleLoop(data[i].children)
        } else {
          continue
        }
        if (data.length - 1 === i) {
          break
        }
      }
    }
  },
  watch: {
    content (val) {
      if (val !== false) {
        this.$nextTick(() => {
          this.form = val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-form {
  height: 100%;
  position: relative;
  padding: 10px 10px 60px 10px;
  .el_real_form {
    height: 100%;
    overflow-y: auto;
  }
  .submit_box {
    position: absolute;
    text-align: center;
    width: calc(100% - 40px);
    height: 60px;
    bottom: 0;
    padding: 10px;
    background: #FFF;
    border-top: 1px solid #eee;
    .button {
      width: 180px;
    }
  }
}
</style>

<template>
  <div class="c-tree-filter" v-if="formItem.type === 'tree-filter-radio'">
    <el-form-item label="跳转类型">
      <el-radio-group v-model="radio">
        <el-radio :label="1">页面</el-radio>
        <el-radio :label="2">外链</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="radio === 1" :label="handleLabel(formItem)" :prop="formItem.id"
                  :rules="filter_rules(formItem.rules)" key="myPage">
      <div class="tree-box">
        <div class="tree-filter-input" v-show="treeShow">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText">
          </el-input>
        </div>
        <el-tree
          v-if="treeShow"
          :data="treeFilterData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          default-expand-all
          node-key="id"
          ref="treeFilter">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ data.pageName }}</span>
            <span>
              <el-button type="text" size="mini" @click="choose(data)">选择</el-button>
            </span>
          </span>
        </el-tree>
        <p class="tree-check-p" v-else>{{treeActiveData.pageName}} <span class="span_rep"
                                                                         @click="handleReplace">更换</span><span
          class="span_del" @click="handleBindRemove">删除</span></p>
      </div>
    </el-form-item>
    <el-form-item v-else-if="radio === 2" :label="handleLabel(formItem)" :prop="formItem.id"
                  :rules="filter_rules(formItem.rules)" key="outPage">
      <el-input v-model="treeActiveData.url" :name="formItem.id" :placeholder="formItem.inputPlaceholder"
                @blur="handleBlur(treeActiveData)"></el-input>
    </el-form-item>
  </div>
  <div class="c-tree-filter" v-else-if="formItem.type === 'tree-filter-default'">
    <el-form-item :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <div class="tree-box">
        <div class="tree-filter-input" v-show="treeShow">
          <el-input
            placeholder="输入关键字进行过滤"
            v-model="filterText">
          </el-input>
        </div>
        <el-tree
          v-if="treeShow"
          :data="treeFilterData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          default-expand-all
          node-key="id"
          ref="treeFilter">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
              <el-button
                type="text"
                size="mini"
                @click="choose(data)">
                选择
              </el-button>
            </span>
          </span>
        </el-tree>
        <p v-else>{{treeActiveData.name}} <span style="color: #409EFF;" @click="handleReplace">更换</span></p>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'c-tree-filter',
  props: {
    formField: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      treeFilterData: [],
      filterText: '',
      device: null,
      defaultProps: {
        children: 'sub',
        label: 'pageName'
      },
      terms: [],
      treeShow: true,
      treeActiveData: {},
      radio: 1
    }
  },
  computed: {
    companyInfo () {
      return this.$store.state.companyInfo
    },
    equipInfo () {
      return this.$store.state.equipInfo
    }
  },
  created () {
  },
  mounted () {
    this.device = parseInt(this.$Cookies.get('device'))
    this.initQuery()
  },
  methods: {
    initQuery () {
      this.queryAllPage()
    },
    /*
    * 查询全部分类
    * @method queryPage
    * @param {}
    * @return
    */
    queryAllPage () {
      this.$axios.get('/athena/page/tree', {
        deviceId: this.equipInfo.id,
        companyId: this.companyInfo.companyId
      }).then(res => {
        this.treeFilterData = []
        if (res.code === '0000') {
          this.treeFilterData = [res.data]
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    // 过滤
    // filterNode (value, data) {
    //   if (!value) return true
    //   return data.name.indexOf(value) !== -1
    // },
    filterNode (value, data, node) {
      if (!value) {
        return true
      }
      if (data.pageName.indexOf(value) !== -1) {
        return true
      }
      return this.checkBeLongToChooseNode(value, data, node)
    },
    // 判断传入的节点是不是选中节点的子节点
    checkBeLongToChooseNode (value, data, node) {
      const level = node.level
      if (level === 1) {
        return false
      }
      let parentData = node.parent
      let index = 0
      while (index < level - 1) {
        if (parentData.data.pageName.indexOf(value) !== -1) {
          return true
        }
        parentData = parentData.parent
        index++
      }
      return false
    },
    choose (data) {
      this.treeShow = false
      let param = {
        ...data,
        radio: this.radio
      }
      delete param.nextPageList
      this.$emit('update:formField', param)
    },
    handleBlur (data) {
      let obj = {
        page_id: '',
        name: '外跳链接',
        url: data.url,
        radio: this.radio
      }
      this.$emit('update:formField', obj)
    },
    handleReplace () {
      this.treeShow = true
      this.queryAllPage()
    },
    handleBindRemove () {
      // let obj = {}
      // this.$emit('update:formField', obj)
      // this.queryAllPage()
      this.treeShow = true
      this.$emit('update:formField', null)
    }
  },
  watch: {
    formField (val) {
      if (val !== null) {
        this.$nextTick(() => {
          this.treeShow = false
          this.treeActiveData = val
          this.radio = val.radio
        })
      } else {
        this.initQuery()
      }
    },
    filterText (val) {
      this.$refs.treeFilter.filter(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-tree-filter {
  .tree-box {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    outline: none;
    position: relative;
    .tree-filter-input {
      margin: 10px;
    }
    .tree-check-p {
      .span_rep {
        color: #409EFF;
        &:hover {
          opacity: 0.8;
        }
      }
      .span_del {
        color: #F56C6C;
        margin-left: 5px;
        /*float: right;*/
        /*margin-right: 5px;*/
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
}
</style>

<template>
  <div class="c-radio" v-if="formItem.type === 'radio'">
    <el-form-item :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <el-radio-group v-model="radio" @change="handleChange">
        <el-radio v-for="(item, index) in formItem.radiosConfig" :key="index" :label="item.value" :disabled="item.disabled">{{item.name}}</el-radio>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'c-radio',
  props: {
    formField: [String, Number, Boolean, Array],
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      radio: '',
      page_content: {}
    }
  },
  created () {
    this.radio = this.formItem.defaultValue
    this.handleFun(this.formField)
  },
  computed: {
  },
  methods: {
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleChange (data) {
      this.$emit('update:formField', data)
    },
    handleFun (val) {
      if (!this.$CheckValue(val)) {
        if (typeof val === 'string') {
          this.radio = val
        }
      } else {
        this.radio = this.formItem.defaultValue
        this.$emit('update:formField', this.radio)
      }
    }
  },
  watch: {
    formField (val) {
      if (!this.$CheckValue(val)) {
        if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') {
          this.radio = val
        }
      } else {
        this.radio = this.formItem.defaultValue
        this.$emit('update:formField', this.radio)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

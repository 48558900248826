<template>
  <div class="tree_main">
    <div class="content">
      <span @click="expanded=!expanded" class="pre_span" :class="{open: expanded}"></span>
      <span @click="nodeClick(data)" @dblclick="expanded=!expanded" class="label"
            :class="{select: data.folderId===selectData.folderId}">{{data.folderName}}</span>
    </div>
    <div v-if="expanded" class="sub_box">
      <Node v-for="(node, index) in nodeData" :data="node" :key="node.id" @node-click="nodeClick"
            :indexInfo="{total: nodeData.length, index: index+1}" :selectData="selectData"></Node>
    </div>
  </div>
</template>

<script>
import Node from './Node'

const GetParent = (data, id, key) => {
  let arr = []
  let curData = data[id]
  if (key) {
    arr.unshift(curData[key])
  } else {
    arr.unshift(curData)
  }
  if (curData.parentId) {
    let preData = GetParent(data, curData.parentId, key)
    arr = [...preData, ...arr]
  }
  return arr
}

const loopMenu = (data, obj, parentId) => {
  data.forEach(item => {
    const { folderId, folderName, sub } = item
    obj[item.folderId] = { folderName, folderId, parentId, sub }
    if (item.sub) {
      loopMenu(item.sub, obj, folderId)
    }
  })
  return obj
}
const GetMenuInfo = (data) => {
  let obj = {}
  return loopMenu(data, obj, '')
}
export default {
  name: 'FileTree',
  components: { Node },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    currentNode: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      nodeData: [],
      expanded: true,
      selectData: {},
      dataMap: {}
    }
  },
  computed: {
    expandIds () {
      return GetParent(this.dataMap, this.currentNode.folderId)
    }
  },
  watch: {
    data () {
      this.getDataMap()
      this.setNodeData()
    },
    currentNode () {
      this.selectData = { ...this.currentNode }
    }
  },
  mounted () {
    this.getDataMap()
    this.setNodeData()
    this.selectData = { ...this.currentNode }
  },
  methods: {
    nodeClick (data) {
      this.$emit('node-click', data)
      this.selectData = data
    },
    checkChildren ({ sub }) {
      let num = sub && sub.length
      return !!num
    },
    setNodeData () {
      if (this.checkChildren(this.data)) {
        this.nodeData = this.transfer(this.data.sub, this.data.folderId)
      }
    },
    transfer (data, parentId) {
      if (!data) return []
      return data.map(item => {
        if (item.folderId === this.currentNode.folderId) {
          this.$emit('update:currentNode', item)
        }
        return {
          parentId,
          folderId: item.folderId,
          folderName: item.folderName,
          expanded: this.expandIds.includes(item.folderId),
          sub: this.transfer(item.sub, item.folderId)
        }
      })
    },
    getDataMap () {
      this.dataMap = GetMenuInfo([this.data])
    }
  }
}
</script>

<style scoped lang="scss">
.tree_main {
  font-size: 12px;
  font-family: PingFangSC-Regular;
}
.content {
  .label {
    padding: 1px 3px 0 0;
    margin: 0;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    color: #333;
    background-color: transparent;
    text-decoration: none;
    vertical-align: top;
    display: inline-block;
    &.select {
      background-color: #EBF1F9;
      color: #3089DC;
      height: 24px;
      opacity: 0.8;
    }
  }
  .pre_span {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    border: 0 none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: url(https://img.alicdn.com/tfs/TB1wZpDnBTH8KJjy0FiXXcRsXXa-296-296.png);
    background-size: 148px 148px;
    &.open {
      background-position: -97px -106px;
    }
    background-position: -55px -106px;
  }
}
.sub_box {
  padding-left: 16px;
}
</style>

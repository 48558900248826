<template>
  <div id="media-main">
    <div class="media-main__wrapper">
      <ul class="attachments-browser" ref="browse_scroll">
        <template>
          <li class="attachment" title="上传文件">
            <div class="add_box" @click="addPicture">
              <i class="el-icon-plus"></i>
            </div>
          </li>
          <li class="attachment"
              :title="item.name" v-for="(item) in mediaArr"
              :class="{actived: item.check}"
              :key="item.id"
              @click.exact="!$event.ctrlKey && !$event.shiftKey && attachmentFn(item)"
              @click.ctrl="!$event.shiftKey && selection.selectType === 'multiple' ? attachmentKeyUpCtrlFn(item) : attachmentFn(item)"
              @click.meta="!$event.shiftKey && selection.selectType === 'multiple' ? attachmentKeyUpCtrlFn(item) : attachmentFn(item)"
              @click.shift="!$event.ctrlKey && selection.selectType === 'multiple' ? attachmentKeyUpShiftFn(item) : attachmentFn(item)">
            <div class="attachment-preview">
              <div class="thumbnail" v-if="item.status === 'success' || item.status === 'finish'" key="finish">
                <div class="centered">
                  <div class="centered-icon" v-if="item.type === 'video'">
                    <svg v-if="item.url === null" viewBox="0 0 1024 1024" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                      <path
                        d="M849.5 962l-675 0c-66.284 0-112.5-46.216-112.5-112.5l0-675c0-66.284 46.216-112.5 112.5-112.5l675 0c66.284 0 112.5 46.216 112.5 112.5l0 675c0 66.284-46.216 112.5-112.5 112.5zM99.5 212l0 75 150 0-72.858-182.135c-46.619 13.532-77.142 53.522-77.142 107.135zM249.5 99.5l75.001 187.5 112.481 0-74.982-187.5-112.5 0zM436.982 99.5l75.018 187.5 112.5 0-75-187.5-112.519 0zM624.5 99.5l75 187.5 112.5 0-75-187.5-112.5 0zM924.5 212c0-66.284-46.216-112.5-112.5-112.5l75.001 187.5 37.499 0 0-75zM924.5 324.5l-824.999 0 0 487.5c0 66.284 46.216 112.5 112.5 112.5l600 0c66.284 0 112.5-46.216 112.5-112.5l0-487.5zM362 437l337.5 187.5-337.5 187.5 0-374.999z">
                      </path>
                    </svg>
                    <img :src="item.url + '&vframe/png/offset/3/w/480/'" alt="" v-else>
                  </div>
                  <img :src="item.url+'&imageView2/2/w/256/h/256'" alt="" v-else>
                </div>
                <div class="filename">
                  {{item.name}}
                </div>
              </div>
              <div class="attachment-status" v-else-if="item.status === 'uploading'" key="uploading">
                <p><i class="el-icon-loading"></i>上传中...</p>
              </div>
              <div class="attachment-status" v-else-if="item.status === 'waiting'" key="waiting">
                <p><i class="el-icon-loading"></i>排队中...</p>
              </div>
              <div class="attachment-status" v-else-if="item.status === 'ready'" key="ready">
                <p><i class="el-icon-loading"></i>等待中...</p>
              </div>
              <div class="thumbnail" v-else-if="item.status === 'error'" key="error">
                <div class="centered error">
                  <p><i class="el-icon-picture-outline"></i>上传失败</p>
                  <p style="color: red;">{{item.error}}</p>
                </div>
                <div class="filename">
                  {{item.name}}
                </div>
              </div>
              <div class="attachment-status" v-else>
                <p><i class="el-icon-picture-outline"></i>上传失败</p>
              </div>
            </div>
            <el-button type="primary" class="attachment-check" v-show="item.check">
              <i class="el-icon-check"></i>
            </el-button>
          </li>
        </template>
      </ul>
      <div class="browser-return" v-show="browserReturn" @click="handleScrollReturn">
        <div class="return_icon">
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <path
              d="M954.272957 30.6788c9.501125 9.516114 14.267675 21.001802 14.267675 34.438079 0 13.452265-4.766551 24.937954-14.267675 34.439079-9.517113 9.517113-21.154692 14.267675-34.930723 14.267675H102.645223c-13.775032 0-25.428599-4.750562-34.930723-14.267675-9.516114-9.502124-14.267675-20.986813-14.267676-34.439079 0-13.436277 4.751562-24.921966 14.267676-34.438079 9.502124-9.501125 21.155691-14.268675 34.930723-14.268675h816.697011c13.776031 0 25.41361 4.766551 34.930723 14.268675zM907.533779 535.947171c9.83988 9.83988 14.75932 21.494446 14.759319 34.931722 0 13.453265-4.766551 24.937954-14.267675 34.440078-9.517113 9.517113-21.155691 14.266676-34.930723 14.266676-13.776031 0-25.26172-4.580685-34.439079-13.775031L559.208838 326.360835v648.437769c0 13.775032-4.766551 25.41361-14.267675 34.931722-9.516114 9.500125-21.001802 14.266676-34.439079 14.266676-13.452265 0-24.936955-4.766551-34.439079-14.266676-9.517113-9.517113-14.266676-21.155691-14.266676-34.931722V328.330409L184.315124 604.825329c-9.194347 10.501401-21.001802 15.742608-35.423367 15.742608-13.776031 0-25.429598-4.91944-34.931722-14.75932-9.516114-9.83888-14.266676-21.478457-14.266676-34.931722 0-13.436277 4.91944-25.091843 14.759319-34.931723l361.117684-361.117683c9.83988-10.485412 21.647335-15.742608 35.422367-15.742608s25.583487 5.257196 35.422367 15.742608l361.118683 361.119682z">
            </path>
          </svg>
        </div>
        <div class="return_info">
          返回顶部
        </div>
      </div>
      <MLoading :loading="loading" :content="loadingContent" :loadingCls="loadingCls"></MLoading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'media-main',
  props: {
    mediaArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeMediasArrLib: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeStartIndexLib: {
      type: Boolean,
      default: false
    },
    // 绑定和移除滚动条事件
    mainEventScroll: {
      type: Boolean,
      default: false
    },
    mediaMultiple: {
      type: Boolean,
      default: true
    },
    selection: {
      type: Object,
      default: () => {
        return {
          selectType: 'multiple',
          number: 20000
        }
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    loadingContent: {
      type: String,
      default: '数据加载中...'
    },
    loadingCls: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      mediaInfo: {},
      mediaKeyUpArr: [],
      activeMediasArr: [],
      activeIndex: {
        startIndex: null,
        endIndex: null
      },
      browserReturn: false,
      imageLoading: false
    }
  },
  created () {
  },
  mounted () {
    this.$refs.browse_scroll.addEventListener('scroll', this.handleScroll, false)
  },
  computed: {},
  methods: {
    addPicture () {
      this.$parent.openUpload()
    },
    // 点击 选中和随时更新mediaArr的数据
    attachmentFn (data) {
      data.check = !data.check
      /*
      *  1.鼠标点击模式:
      *     只会单个选中,显示最新的选中
      */
      this.mediaArr.forEach((item) => {
        if (item === data && data.check === true) {
          this.mediaInfo = data
          if (this.activeMediasArr.length > 0) {
            this.activeMediasArr = []
          }
          this.activeMediasArr.push(data)
        } else if (item === data && data.check === false) {
          if (this.activeMediasArr.length > 0) {
            this.activeMediasArr = []
          }
        } else {
          item.check = false
        }
      })
      this.activeIndex.startIndex = this.getMediaActiveIndex(data)
      // 添加选中媒体 activeMediasArr
      let inArr = this.in_array(this.activeMediasArr, data)
      if (inArr.is_indexOf) {
        if (data.check === false) {
          this.activeMediasArr.splice(inArr.index, 1)
        }
      }
    },
    /*
    * ctrl 选中的媒体
    *   1.选中的媒体先添加进数组
    *   2.后面在操作相同的媒体先做判断
    *       1)含有这个 , 判断 是否 选中 , -> 如果选中 忽略
    *                                 -> 不选中, 找出对应的索引, 然后替换
    */
    attachmentKeyUpCtrlFn (data) {
      data.check = !data.check
      if (data.check) {
        this.mediaInfo = data
        this.activeMediasArr.push(data)
      }
      if (this.activeIndex.startIndex === null) {
        this.activeIndex.startIndex = this.getMediaActiveIndex(data)
      } else {
        if (this.activeIndex.startIndex > this.getMediaActiveIndex(data)) {
          this.activeIndex.startIndex = this.getMediaActiveIndex(data)
        }
      }
      let inArr = this.in_array(this.activeMediasArr, data)
      if (inArr.is_indexOf) {
        if (data.check === false) {
          this.activeMediasArr.splice(inArr.index, 1)
        }
      }
    },
    /*
    * shift 选中的媒体
    *   1. 开头选中的索引,末尾选中的索引,取它们(包括自己)区间选中的值
    */
    attachmentKeyUpShiftFn (data) {
      data.check = !data.check
      if (data.check) {
        this.mediaInfo = data
      }

      if (this.activeIndex.startIndex === null) {
        this.activeIndex.startIndex = this.getMediaActiveIndex(data)
      } else {
        let index = this.getMediaActiveIndex(data)
        if (index < this.activeIndex.startIndex) {
          this.activeIndex.endIndex = this.activeIndex.startIndex
          this.activeIndex.startIndex = index
        } else {
          this.activeIndex.endIndex = index
        }
      }
      this.indexActiveMedia(this.activeIndex.startIndex, this.activeIndex.endIndex)
      if (this.activeIndex.startIndex !== null && this.activeIndex.endIndex !== null) {
        let arr = this.mediaArr
        let nArr = arr.slice(this.activeIndex.startIndex, this.activeIndex.endIndex + 1)
        this.activeMediasArr = nArr
      }
    },
    // 判断数组是否含有这个值
    in_array (arr, obj) {
      let h = {
        is_indexOf: false,
        index: -1
      }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === obj.id) {
          h = {
            is_indexOf: true,
            index: i
          }
          break
        }
      }
      return h
    },
    // 取索引
    getMediaActiveIndex (data) {
      let indx = null
      let iArr = this.in_array(this.mediaArr, data)
      if (iArr.index !== -1) {
        indx = iArr.index
      }
      return indx
    },
    indexActiveMedia (start = 0, end = 0) {
      for (let i = start; i <= end; i++) {
        this.mediaArr[i].check = true
      }
    },
    // 滚动条
    handleScroll () {
      let scrollTop = this.$refs.browse_scroll.scrollTop
      let clientHeight = this.$refs.browse_scroll.clientHeight
      let scrollHeight = this.$refs.browse_scroll.scrollHeight
      let scrollObj = {
        scrollTop: scrollTop,
        scrollHeight: scrollHeight,
        clientHeight: clientHeight
      }
      if (scrollTop > 20) {
        this.browserReturn = true
      } else {
        this.browserReturn = false
      }
      this.$emit('handleScroll', scrollObj)
    },
    // 返回顶部
    handleScrollReturn () {
      this.$refs.browse_scroll.scrollTop = 0
    }
  },
  watch: {
    activeMediasArr (val) {
      this.$emit('update:activeMediasArrLib', val)
    },
    activeMediasArrLib (val) {
      if (this.activeStartIndexLib) {
        this.activeIndex.startIndex = 0
        this.$emit('update:activeStartIndexLib', false)
      }
      this.activeMediasArr = val
    },
    /*
    * 监听筛选数据 是否移除滚动事件,滚动条复位
    * @method mainEventScroll
    * @params {Boolean} val
    */
    mainEventScroll (val) {
      if (val) {
        this.$refs.browse_scroll.removeEventListener('scroll', this.handleScroll, false)
      } else {
        this.$refs.browse_scroll.addEventListener('scroll', this.handleScroll, false)
      }
    },
    mediaArr (val) {
      if (val.length === 0) {
        this.mediaInfo = {}
      }
    }
  }
}
</script>
<style lang="scss">
.centered-icon {
  svg {
    width: 50px;
    height: 50px;
    fill: #8a8787;
  }
}
.browser-return {
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  z-index: 10;
  right: 25px;
  bottom: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #373d41;
  transition: all 0.2s ease-in;
  overflow: hidden;
  border-radius: 50%;
  .return_icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    border-radius: 50%;
  }
  &:hover {
    width: 120px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  svg {
    width: 20px;
    height: 20px;
    /*fill: #409EFF;*/
    fill: #fff;
    position: relative;
    z-index: 1;
  }
  .return_info {
    width: 112px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #373d41;
    text-align: left;
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;

  }
}
.attachments-browser {
  &::-webkit-scrollbar { /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(55, 61, 65, 0.25);
  }
  &::-webkit-scrollbar-track { /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #EDEDED;
  }
}
</style>
<style lang="scss" scoped>
#media-main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &:after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
  .media-main__wrapper {
    height: 100%;
    padding-top: 15px;
    position: relative;
    box-sizing: border-box;
    .attachments-browser {
      position: absolute;
      top: 0;
      left: 0;
      right: 0px;
      bottom: 0;
      overflow-y: scroll;
      outline: 0;
      .attachment {
        position: relative;
        float: left;
        padding: 8px;
        margin: 0;
        color: #444;
        cursor: pointer;
        text-align: center;
        user-select: none;
        box-sizing: border-box;
        width: 20%;
        .add_box {
          display: flex;
          align-items: center; /*垂直居中*/
          justify-content: center; /*水平居中*/
          border: 1px solid #ccc;
          font-size: 40px;
          &::before {
            content: "";
            display: block;
            padding-top: 100%;
          }
        }
        &.actived {
          box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 6px #409EFF;
          .attachment-check {
            background-color: #409EFF;
            box-shadow: 0 0 0 0px #fff, 0 0 0 2px #409EFF;
          }
        }
        .attachment-preview {
          position: relative;
          box-shadow: inset 0 0 15px rgba(0, 0, 0, .1), inset 0 0 0 1px rgba(0, 0, 0, .05);
          background: #eee;
          cursor: pointer;
          &::before {
            content: "";
            display: block;
            padding-top: 100%;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
            overflow: hidden;
          }
          .thumbnail {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 1;
            transition: opacity .1s;
            .centered {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              transform: translate(50%, 50%);
              img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                transform: translate(-50%, -50%);
              }
              p {
                font-size: 16px;
                transform: translate(-50%, -50%);
                i {
                  font-size: 22px;
                  vertical-align: middle;
                  margin-right: 6px;
                }
              }
              .centered-icon {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                transform: translate(-50%, -50%);
                font-size: 16px;
                img {
                  transform: initial;
                  position: relative;
                }
              }
              &.error {
                transform: translate(50%, 38%);
              }
            }
          }
          .attachment-status {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            i {
              font-size: 22px;
              vertical-align: middle;
              margin-right: 6px;
              margin-top: -4px;
            }
          }
        }
        .filename {
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          position: absolute;
          bottom: 0;
          left: 50%;
          right: 0;
          width: 100%;
          height: 24px;
          line-height: 24px;
          padding: 0 6px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transform: translateX(-50%);
        }
        .attachment-check {
          display: block;
          height: 24px;
          width: 24px;
          padding: 0;
          border: 0;
          position: absolute;
          z-index: 10;
          right: 20px;
          top: 20px;
          outline: 0;
          background: #409EFF;
          cursor: pointer;
          border-radius: 50%;

        }
      }
      &::after {
        content: '';
        display: block;
        height: 0;
        visibility: hidden;
        clear: both;
      }
    }
    .attachments-tip {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0px;
      bottom: 0;
      overflow: auto;
      outline: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

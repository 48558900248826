<template>
  <div class="c-form-color-picker">
    <!--区分多个input-->
    <el-form-item v-if="formItem.type === 'color-picker'" :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <el-color-picker
        v-model="color"
        :show-alpha="formItem.showAlpha === 'rgba' ? true : false"
        @change="handleChange"
        :predefine="predefineColors">
      </el-color-picker>
      <!--<div class="desc">-->
        <!--{{formItem.desc}}-->
      <!--</div>-->
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'c-form-input',
  props: {
    formField: {
      type: String,
      default: ''
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      color: '',
      predefineColors: [
        '#000000',
        '#FFFFFF',
        '#FF0000',
        '#00FF00',
        '#0000FF',
        '#FFFF00',
        '#00FFFF',
        '#FF00FF',
        '#C0C0C0'
      ]
    }
  },
  created () {
    if (!this.$CheckValue(this.formField)) {
      this.color = this.formField
    } else {
      this.color = this.formItem.defaultValue
    }
    this.$emit('update:formField', this.color)
  },
  methods: {
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleChange (data) {
      this.$emit('update:formField', data)
      this.$emit('submit')
    }
  },
  watch: {
    formField (val) {
      if (!this.$CheckValue(val)) {
        this.color = val
      } else {
        this.color = this.formItem.defaultValue
        this.$emit('update:formField', this.color)
      }
    }
  }
}
</script>
<style lang="scss">
  .el-color-predefine__color-selector {
    border: 1px solid #eee;
  }
</style>
<style lang="scss" scoped>
  .c-form-input {
    .desc {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>

import axios from 'axios'
import { Message } from 'element-ui'

const instance = axios.create({
  baseURL: '/api',
  timeout: 60000
})

const checkStatus = response => {
  if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
    return response.data
  }
  return new Error('service status error')
}

function checkCode(res) {
  if (res.code !== '0000') {
    if (res.code === '102') {
      const { href } = location
      window.location.href = `/api/sso/auth/jump?service=${href}`
    } else {
      if (/[\u4e00-\u9fa5]/.test(res.msg) && res.msg.length < 100) {
        Message.error(res.msg)
      } else {
        Message.error('后台服务出错！')
      }
    }
  }
  return res
}

export default {
  post(url, data) {
    return instance({
      method: 'post',
      url,
      data: data
    }).then(checkStatus).then(checkCode)
  },
  get(url, params) {
    return instance({
      method: 'get',
      url,
      params
    }).then(checkStatus).then(checkCode)
  }
}

<template>
  <div id="media-footer">
    <div class="media-footer-progress">
    </div>
    <div class="media-footer-btn">
      <el-button type="primary" :disabled="btnDisabled" class="media-footer-btn__insert" @click="submit">插入至相册
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'media-footer',
  props: {
    activeMediasArr: {
      type: Array,
      default: () => {
        return []
      }
    },
    uploadingDisabledBtn: {
      type: Boolean,
      default: false
    },
    mediasShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      btnDisabled: true
    }
  },
  computed: {
    companyInfo () {
      return this.$store.state.companyInfo
    }
  },
  methods: {
    submit () {
      this.$emit('mediasClose', false)
    },
    mediaInfoSize (data) {
      let fileSize = (data / 1024 / 1024) * 10
      if (fileSize < 1) {
        fileSize = Math.floor((data / 1024) * 10) / 10 + 'KB'
      } else {
        fileSize = Math.floor(fileSize) / 10

        fileSize = fileSize + 'MB'
      }
      return fileSize
    }
  },
  watch: {
    activeMediasArr (val) {
      if (val.length > 0) {
        this.btnDisabled = false
        if (this.uploadingDisabledBtn) {
          this.btnDisabled = true
        }
      } else {
        this.btnDisabled = true
      }
    },
    uploadingDisabledBtn (val) {
      if (!val) {
        this.btnDisabled = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#media-footer {
  background-color: #fcfcfc;
  line-height: 50px;
  border-top: 1px solid #e4e7ed;
  padding: 0 16px;
  .media-footer-btn {
    float: right;
    .media-footer-btn__insert {
      padding: 8px 14px;
    }
  }
  .media-footer-progress {
    float: left;
    position: relative;
    text-align: left;
    .progress {
      span {
        margin-right: 5px;
      }
    }
  }
  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}
</style>

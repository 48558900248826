import axios from '../../utils/oldAxios'
import { AppCode } from '@/utils/config'

export default {
  state: {
    TEMPLATE_TYPE_CODE: [], // 模板所属类型
    TEMPLATE_SCHEMA_CODE: [], // 模板所属主题
    TEMPLATE_BIZ_CODE: [], // 模板所属分类
    TEMPLATE_MODULE_CODE: [], // 模板所属组件
    TEMPLATE_COLOR_CODE: [] // 模板所属颜色
  },
  mutations: {
    setStateData(state, { type, data }) {
      state[type] = data
    }
  },
  actions: {
    async getDictionary({ commit, state }, type) {
      if (state[type].length === 0) {
        const response = await axios.get('/system/dictionary/list', { dicType: type, appCode: AppCode })
        const commitData = response.data.map(item => {
          return { value: item.value, label: item.label }
        })
        commit('setStateData', { type: type, data: commitData })
        return commitData
      }
    }
  }
}

<template>
  <GTree
    :tree-data="folderAll"
    :default-expanded-keys="defaultExpanded"
    :default-selected-keys="defaultExpanded[0]"
    :replace-fields="{
      key: 'folderId',
      value: 'folderId',
      label: 'folderName',
      children: 'sub'
    }"
    @select="checkFolderAll"
  />
<!--  <FileTree :data="folderAll" :currentNode.sync="currentFolder" @node-click="checkFolderAll"-->
<!--            class="tree_box"></FileTree>-->
</template>

<script>
import FileTree from '@/components/FileTree/index'
import GTree from '@/components/GTree'

export default {
  name: 'FolderTree',
  components: { FileTree, GTree },
  data () {
    return {
      treeProps: { label: 'folderName', value: 'wbKM3mbdV1', children: 'sub' },
      currentFolder: {},
      folderAll: [],
      defaultExpanded: []
    }
  },
  mounted () {
    this.getAllFolder()
  },
  methods: {
    async getAllFolder () {
      const { data, code } = await this.$axios.get('/media/folder/tree')
      if (code === '0000') {
        this.folderAll = [data]
        this.defaultExpanded = [this.folderAll[0].folderId]
        if (!this.currentFolder.folderId) this.currentFolder = data
      }
      this.$emit('change', this.currentFolder)
    },
    checkFolderAll (data) {
      this.currentFolder = data
      this.$emit('change', this.currentFolder)
    }
  }
}
</script>

<style scoped lang="scss">
  .g-tree{
    float: left;
    width: 180px;
    height: 100%;
    overflow: auto;
    border-right: 1px $themeBg solid;
  }
</style>

<template>
  <div class="c-loading" v-if="cLoading">
    <!--加载-->
    <div class="loading-box">
      <div class="el-loading-spinner">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
        <p :class="clsType">{{content}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MLoading',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: '数据加载中...'
    },
    loadingCls: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cLoading: true
    }
  },
  created () {
  },
  computed: {
    clsType () {
      if (this.loadingCls === 'uploadingCls') {
        return {
          uploadingCls: true
        }
      } else {
        return {}
      }
    }
  },
  methods: {},
  watch: {
    loading (val) {
      this.cLoading = val
    }
  }
}
</script>

<style lang="scss">
.c-loading {
  .loading-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10;
    .uploadingCls {
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 16px;
    }
  }
}
@keyframes loading-ratate {
  100% {
    transform: rotate(360deg);
  }
}
</style>

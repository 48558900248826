<template>
  <div class="c-form-medias">
    <!--区分多个-->
    <el-form-item
      v-if="formItem.type === 'medias'"
      :label="handleLabel(formItem)"
      :prop="formItem.id"
      :rules="filter_rules(formItem.rules)"
    >
      <div class="formVideo">
        <div id="medias-box" class="box">
          <Editor
            :id="id"
            :init="editInit"
            @onInit="initQuery()"
            @onBlur="onBlur"
            @onFocus="onFocus"
            @onNodeChange="setCurrent"
            @onChange="setCurrent"
            @onDragOver="mouseEnterFlag && handleDragenter($event)"
            @onDrop="mouseEnterFlag && handleDrop($event)"
            @onMouseOut="onMouseOut"
            @onMouseDown="onMouseDown"
          >
          </Editor>
        </div>
      </div>
      <div class="btn" @click="handleMedias">
        <el-button class="add-media" icon="el-icon-video-camera" circle type="primary" title="添加媒体">
          <span class="add-placeholder">添加媒体</span>
        </el-button>
      </div>
      <div
        v-show="mediaUploadShow"
        class="media-upload"
        @drop="handleDrop"
        @dragleave="handleDragleave"
        @dragover="handleDragover"
      >
        <div class="media-upload-line">
        </div>
        <p>
          托文件至此可上传
        </p>
      </div>
    </el-form-item>

    <!--媒体库-->
    <Medias :medias-show.sync="mediasShow" :data-files.sync="dataFiles" :external-files="mediasFiles"></Medias>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/modern/theme'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/autoresize'
import Function from '@/utils/function'

export default {
  name: 'CFormMedias',
  components: {
    Editor
  },
  props: {
    formField: {
      type: Array,
      default: () => {
        return []
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      mediasShow: false,
      dataFiles: [],
      editInit: {
        selector: 'textarea',
        content_style: '.mce-content-body { background-color:#fbfdff }',
        plugins: 'autoresize',
        autoresize_min_height: 400,
        autoresize_overflow_padding: 10,
        autoresize_bottom_margin: 50,
        menubar: false,
        toolbar: false,
        toolbar1: false,
        statusbar: false,
        height: 400,
        paste_data_images: true,
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        language_url: '/static/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skins/lightgray',
        toolbar_items_size: 'small'
      },
      id: 'tinymce',
      tyIndex: null,
      currentMediaLen: 0,
      mediaUploadShow: false,
      mediasFiles: null,
      mouseEnterFlag: true
    }
  },
  computed: {},
  watch: {
    dataFiles(val) {
      tinymce.activeEditor.selection.moveToBookmark(this.tyIndex)
      tinymce.activeEditor.focus()
      val.forEach((item, index) => {
        const shortUrl = item.url + '&imageView2/2/w/640'
        if (item.type === 'image') {
          tinymce.activeEditor.selection.setNode(tinymce.get(this.id).dom.create('img',
            {
              src: shortUrl,
              width: 152,
              height: 86,
              'data-id': item.id,
              'data-src': item.url,
              'data-thum': shortUrl,
              'data-type': item.type,
              'data-test': 'fk'
            }
          ))
        } else if (item.type === 'video') {
          const video = `<span contenteditable="false" style="display:inline-block;">
            <video controls="controls" style="width: 100%;">
              <source src="${shortUrl}" data-src="${item.url}" data-thum="${shortUrl}" data-id="${item.id}" data-type="${item.type}" type="video/mp4" />
              Your browser does not support the video element.'
            </video>
          </span>`
          tinymce.activeEditor.selection.setContent(video)
        }
      })
    },
    formField(val) {
      if (val !== null && val.length !== this.currentMediaLen && val.length > this.currentMediaLen) {
        this.initQuery()
        this.currentMediaLen = val.length
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    initQuery() {
      if (Function.checkValue(this.formField)) {
        return
      }
      const htmlArr = []
      this.formField.forEach(item => {
        if (item.url.lastIndexOf('.jpg') !== -1) {
          item.url = item.url.substr(0, item.url.lastIndexOf('.jpg') + 4)
        } else if (item.url.lastIndexOf('.png') !== -1) {
          item.url = item.url.substr(0, item.url.lastIndexOf('.png') + 4)
        } else if (item.url.lastIndexOf('.gif') !== -1) {
          item.url = item.url.substr(0, item.url.lastIndexOf('.gif') + 4)
        }
        let contentHtml = ''
        if (item.type === 'image' || item.type.indexOf('image') !== -1) {
          contentHtml = `<img src="${item.thum}" width="152" height="86" data-id="${item.id}" data-src="${item.url}" data-thum="${item.thum}" data-type="image" style="cursor: pointer"/>`
        } else if (item.type === 'video' || item.type.indexOf('video') !== -1) {
          contentHtml += `<span contenteditable="false" style="display:inline-block;">
            <video controls="controls" style="width: 100%;">
              <source src="${item.url}" data-src="${item.url}" data-id="${item.id}" data-type="${item.type}" data-thum="${item.thum}" type="video/mp4" style="cursor: pointer"/>
              Your browser does not support the video element.'
            </video>
          </span>`
        }
        htmlArr.push(contentHtml)
      })
      tinymce.get(this.id).setContent(htmlArr.join(' '))
    },
    setCurrent(e) {
      const val = e.target.getContent({ format: 'raw' })
      if (val.indexOf('img') !== -1 || val.indexOf('video') !== -1) {
        this.fetchDataNew(val)
      } else {
        this.$emit('update:formField', [])
      }
    },
    fetchDataNew(val) {
      const newValue = []
      if (val.indexOf('<p>') !== -1) {
        const str = val.replace('<p>', '').replace('</p>', '').split('<img')
        str.forEach((item, index) => {
          if (item !== '' && (item.indexOf('http:') !== -1 || item.indexOf('https:') !== -1)) {
            const file = {}
            const idstrArr = item.match(/data-id="(\S*)/g)
            const urlstrArr = item.match(/data-src="(\S*)/g)
            const thumstrArr = item.match(/data-thum="(\S*)/g)
            const typestrArr = item.match(/data-type="(\S*)/g)
            let url = urlstrArr[0].replace('data-src="', '').replace('"', '')
            if (url.lastIndexOf('.jpg') !== -1) {
              url = url.substr(0, url.lastIndexOf('.jpg') + 4)
            } else if (url.lastIndexOf('.png') !== -1) {
              url = url.substr(0, url.lastIndexOf('.png') + 4)
            } else if (url.lastIndexOf('.gif') !== -1) {
              url = url.substr(0, url.lastIndexOf('.gif') + 4)
            }
            const id = idstrArr[0].replace('data-id="', '').replace('"', '')
            file['id'] = id
            file['url'] = url
            let thum = thumstrArr[0].replace('data-thum="', '').replace('"', '')
            if (thum.lastIndexOf('.jpg') !== -1) {
              thum = thum.substr(0, thum.lastIndexOf('.jpg') + 4)
            } else if (thum.lastIndexOf('.png') !== -1) {
              thum = thum.substr(0, thum.lastIndexOf('.png') + 4)
            } else if (thum.lastIndexOf('.gif') !== -1) {
              thum = thum.substr(0, thum.lastIndexOf('.gif') + 4)
            }
            file['thum'] = thum
            const type = typestrArr[0].replace('data-type="', '').replace('"', '')
            file['type'] = type
            newValue.push(file)
            if (idstrArr.length > 1) {
              for (let i = 1; i < idstrArr.length; i++) {
                const file = {}
                const id = idstrArr[i].replace('data-id="', '').replace('"', '')
                file['id'] = parseInt(id)
                const url = urlstrArr[i].replace('data-src="', '').replace('"', '')
                file['url'] = url
                const thum = thumstrArr[0].replace('data-thum="', '').replace('"', '')
                file['thum'] = thum
                const type = typestrArr[i].replace('data-type="', '').replace('"', '')
                file['type'] = type
                newValue.push(file)
              }
            }
          }
        })
      }
      this.$emit('update:formField', newValue)
    },
    onBlur() {
      this.mouseEnterFlag = true
      this.tyIndex = tinymce.activeEditor.selection.getBookmark()
      setTimeout(() => {
        if (!this.mediasShow) {
          this.$emit('submit')
        }
      }, 500)
    },
    handleLabel(formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleMedias() {
      this.mediasShow = true
    },
    handleDragenter(e) {
      e.preventDefault()
      let i = 0
      if (++i === 1) {
        this.mediasFiles = null
        this.mediaUploadShow = true
      }
    },
    handleDrop(e) {
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length < 1) {
        return
      }
      this.mediasFiles = files
      this.mediasShow = true
      this.mediaUploadShow = false
      this.mouseEnterFlag = false
    },
    handleDragleave(e) {
      e.preventDefault()
      this.mediaUploadShow = false
    },
    handleDragover(e) {
      e.preventDefault()
    },
    onFocus() {
      this.mouseEnterFlag = false
    },
    onMouseOut() {
      this.mouseEnterFlag = true
    },
    onMouseDown() {
      this.mouseEnterFlag = false
    }
  }
}
</script>
<style lang="scss">
.c-form-medias {
  .btn {
    color: #409EFF;
    &:hover {
      color: #409EFF;
      svg {
        fill: #409EFF;
      }
    }
    svg {
      top: 3px;
      right: 4px;
      position: relative;
      fill: #409EFF;
    }
  }
}
</style>
<style lang="scss" scoped>
.c-form-medias {
  position: relative;
  .btn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .add-media{
    padding: 4px;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
    ::v-deep{
      &>span{
        width: 0;
        margin: 0;
        display: inline-block;
        line-height: 14px;
        overflow: hidden;
        transition: all .3s;
      }
      .add-placeholder{
        display: inline-block;
        font-size: 12px;
      }
    }
    &:hover{
      ::v-deep{
        &>span{
          width: max-content;
          margin-left: 5px;
        }
      }
    }
  }
  .desc {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .formVideo {
    background-color: #fbfdff;
    /*border: 1px dashed #c0ccda;*/
    border-radius: 6px;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    outline: none;
    position: relative;
    .box {
      position: relative;
      z-index: 10;
      ::v-deep .mce-panel {
        box-sizing: border-box;
      }
    }
    i {
      font-size: 50px;
      color: #8c939d;
    }
    video {
      display: block;
      width: 100%;
    }
    &:focus, &:hover {
      border-color: #409eff;
      color: #409eff;
    }
  }
  .media-upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 360px;
    background-color: #196790;
    opacity: 0.7;
    z-index: 166;
    display: flex;
    justify-content: center;
    align-items: center;
    .media-upload-line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 96%;
      height: 96%;
      border: 1px dashed #fff;
      transform: translate(-50%, -50%);

    }
    p {
      font-size: 40px;
      color: #fff;
    }
  }
}
</style>

/**
 * @Description:
 * @author Guan LinTao
 * @date 2:23 下午
 * 全局配置
 */
import axios from 'axios'
import { cityData } from '@/api/public'

export default {
  namespaced: true,
  state: {
    cityData: []
  },
  mutations: {
    setCityData: (state, data) => {
      state.cityData = data
    }
  },
  actions: {
    // 初始化全局配置
    async iniGlobalConfig({ commit }) {
      return new Promise(resolve => {
        axios.get(cityData).then(res => {
          commit('setCityData', res.data)
        })
        resolve()
      })
    }
  }
}

<template>
  <el-dialog :visible.sync="visible" width="1080px" fullscreen append-to-body>
    <el-upload class="upload_box" ref="upload" accept="image/*,video/*" name="file" multiple drag :file-list="fileList"
               :action="QINIU" :data="uploadData" :on-success="uploadSuccess" :on-progress="onProgress"
               :on-error="uploadError" :before-upload="beforeUpload" :show-file-list="false">
      <p class="upload_tip">将文件拖放到此处上传，或点击上传</p>
    </el-upload>
  </el-dialog>
</template>

<script>
import { QINIU } from '@/utils/config'
import { Upload } from 'element-ui'

export default {
  name: 'Upload',
  components: { 'el-upload': Upload },
  data () {
    return {
      QINIU,
      visible: false,
      folderId: '',
      uploadToken: '',
      fileList: []
    }
  },
  computed: {
    uploadData () {
      return {
        token: this.uploadToken,
        'x:sort': +new Date()
      }
    }
  },
  methods: {
    show (folderId) {
      this.visible = true
      this.folderId = folderId
      this.fileList = []
    },
    async getUploadToken (fileType) {
      const { folderId } = this
      const param = { folderId, fileType }
      const response = await this.$axios.get('/media/qiniu/token', param)
      if (response.code === '0000') {
        this.uploadToken = response.data.uploadToken
        return true
      } else {
        return false
      }
    },
    beforeUpload (file) {
      return this.getUploadToken(file.type)
    },
    onProgress (event, file, fileList) {
      this.$emit('progress', fileList)
      this.visible = false
    },
    uploadSuccess (res, file, fileList) {
      this.$emit('progress', fileList)
      // 上传成功回调方法
      if (res.code !== '0000') {
        this.$message.warning(res.message)
      }
    },
    uploadError (error) {
      const message = JSON.parse(error.message).error
      this.$message.error(message)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__body {
    padding: 20px 50px;
    height: calc(100% - 50px);
  }
  .upload_box {
    height: 100%;
  }
  .el-upload {
    width: 100%;
    height: 100%;
    .el-upload-dragger {
      width: 100%;
      height: 100%;
    }
  }
  .el-upload-list {
    max-height: 300px;
    overflow: auto;
  }
}
.upload_tip {
  line-height: 600px;
  color: #999;
  font-size: 32px;
}
</style>

<template>
  <div class="c-form-video">
    <!--区分多个-->
    <el-form-item v-if="formItem.type === 'video'" :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <div class="formVideo" @click="handleMedias">
        <div class="box">
          <i class="el-icon-plus" v-if="JSON.stringify(formField).indexOf('type') === -1"></i>
          <video :src="formField.url" controls v-else></video>
        </div>
      </div>
      <div class="btn" @click="handleRemove" >
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <path d="M998.758641 178.274473H897.615209v819.691188c0 14.232639-11.519708 25.752346-25.752346 25.752346H152.623125c-14.232639 0-25.727347-11.519708-25.727347-25.752346V178.274473H25.727347C11.493708 178.274473 0 166.754766 0 152.547127c0-14.207639 11.493708-25.727347 25.727347-25.727347h270.406134V25.727347c0-14.207639 11.519708-25.727347 25.752346-25.727347h380.714334c14.232639 0 25.752346 11.519708 25.752346 25.727347v101.092433h270.406134c14.233639 0 25.727347 11.519708 25.727347 25.727347 0 14.207639-11.493708 25.727347-25.727347 25.727346zM676.846814 51.454694H347.639173v75.365086h329.207641V51.454694z m169.262703 126.819779h-667.733046v793.963841h667.733046V178.274473zM321.885827 295.903487c14.233639 0 25.753346 11.519708 25.753346 25.727347V807.735491c0 14.207639-11.519708 25.727347-25.753346 25.727347-14.232639 0-25.752346-11.519708-25.752346-25.727347V321.630834c0-14.207639 11.519708-25.727347 25.752346-25.727347z m190.357167 0c14.233639 0 25.753346 11.519708 25.753346 25.727347V807.735491c0 14.207639-11.519708 25.727347-25.753346 25.727347-14.233639 0-25.753346-11.519708-25.753346-25.727347V321.630834c0-14.207639 11.519708-25.727347 25.753346-25.727347z m190.357167-21.145463c14.232639 0 25.752346 11.519708 25.752346 25.727346v486.131657c0 14.207639-11.519708 25.727347-25.752346 25.727347-14.233639 0-25.753346-11.519708-25.753347-25.727347V300.48537c0-14.207639 11.519708-25.727347 25.753347-25.727346z" fill="" p-id="5035">
          </path>
        </svg>
        <span>移除</span>
      </div>
      <!--<div class="desc">-->
      <!--{{formItem.desc}}-->
      <!--</div>-->
    </el-form-item>

    <!--媒体库-->
    <Medias :mediasShow.sync="mediasShow" :dataFiles.sync="dataFiles" :selection="selection"></Medias>
  </div>
</template>

<script>
export default {
  name: 'c-form-image',
  props: {
    formField: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      mediasShow: false,
      selection: {
        selectType: 'single',
        number: 1
      },
      dataFiles: []
    }
  },
  created () {
  },
  computed: {},
  methods: {
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleMedias () {
      this.mediasShow = true
    },
    handleRemove () {
      this.$emit('update:formField', null)
      this.$emit('submit')
    }
  },
  watch: {
    dataFiles (val) {
      if (val[0].type !== 'video') {
        this.$message({
          type: 'error',
          message: this.formItem.rules.message
        })
      } else {
        let shortUrl = val[0].url + '&imageView2/2/w/640'
        this.$emit('update:formField', {
          id: val[0].id,
          url: val[0].url,
          thum: shortUrl,
          type: val[0].type
        })
        this.$emit('submit')
      }
    }
  }
}
</script>
<style lang="scss">
  .c-form-video {
    .btn {
      color: #f56c6c;
      &:hover {
        color: #f78989;
        svg {
          fill: #f78989;
        }
      }
      svg {
        top: 3px;
        right: 4px;
        position: relative;
        fill: #f56c6c;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .c-form-video {
    .btn {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .desc {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .formVideo {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 100%;
      padding-bottom: 56.25%;
      cursor: pointer;
      vertical-align: top;
      display: inline-block;
      text-align: center;
      outline: none;
      position: relative;
      .box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
      i {
        font-size: 50px;
        color: #8c939d;
      }
      video {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
      &:focus, &:hover {
        border-color: #409eff;
        color: #409eff;
      }
    }
  }
</style>

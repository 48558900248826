export default {
  state: {
    pageInfo: {},
    templateInfo: {}
  },
  mutations: {
    setTemplateState(state, { type, data }) {
      state[type] = data
    }
  }
}

<template>
  <div class="c-checkbox">
    <el-form-item v-if="formItem.type === 'checkbox'" :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <el-checkbox-group v-model="checkList" @change="handleChange">
        <el-checkbox v-for="(item, index) in formItem.checkboxesConfig" :key="index" :label="item.name" :disabled="item.disabled"></el-checkbox>
      </el-checkbox-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'c-checkbox',
  props: {
    formField: {
      type: Array,
      default: () => {
        return []
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      checkList: []
    }
  },
  created () {
    // this.initData(this.formItem.defaultValue)
  },
  methods: {
    initData (data) {
      let arr = []
      this.checkList = []
      if (data.length === 0) {
        this.$emit('update:formField', [])
        this.$emit('submit')
        return
      }
      data.forEach((item, i) => {
        let obj = this.formItem.checkboxesConfig.find(h => {
          return h.name === item
        })
        this.checkList.push(obj.name)
        arr.push(obj)
        if (data.length - 1 === i) {
          arr.sort((a, b) => {
            return a.sort - b.sort
          })
          this.$emit('update:formField', arr)
          this.$emit('submit')
        }
      })
    },
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleChange (data) {
      if (this.$CheckValue(this.formItem.checkboxesConfig)) {
        return
      }
      this.initData(data)
    }
  },
  watch: {
    formField (val) {
      if (val !== undefined && val !== null) {
        this.$nextTick(() => {
          this.checkList = []
          val.forEach(item => {
            this.checkList.push(item.name)
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="c-form-image">
    <!--区分多个-->
    <el-form-item v-if="formItem.type === 'image'" :label="handleLabel(formItem)" :prop="formItem.id" :rules="filter_rules(formItem.rules)">
      <div class="formImage" @click="handleMedias">
        <div class="box">
          <i class="el-icon-plus" v-if="$CheckValue(formField) || JSON.stringify(formField).indexOf('type') === -1"></i>
          <img :src="formField.url" v-else>
        </div>
      </div>
      <div class="btn" @click="handleRemove" >
        <el-button class="delete-btn" icon="el-icon-delete" type="danger" circle title="移除">
          <span class="add-placeholder">移除</span>
        </el-button>
      </div>
    </el-form-item>

    <!--媒体库-->
    <Medias :mediasShow.sync="mediasShow" :dataFiles.sync="dataFiles" :selection="selection"></Medias>
  </div>
</template>

<script>
export default {
  name: 'c-form-image',
  props: {
    formField: {
      type: Object,
      default: () => {
        return {}
      }
    },
    formItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      mediasShow: false,
      selection: {
        selectType: 'single',
        number: 1
      },
      dataFiles: []
    }
  },
  created () {
  },
  computed: {},
  methods: {
    handleLabel (formItem) {
      return formItem.desc !== undefined && formItem.desc !== '' ? formItem.label + ' (' + formItem.desc + ')' : formItem.label
    },
    handleMedias () {
      this.mediasShow = true
    },
    handleRemove () {
      this.$emit('update:formField', null)
      this.$emit('submit')
    }
  },
  watch: {
    dataFiles (val) {
      if (val[0].type !== 'image') {
        this.$message({
          type: 'error',
          message: this.formItem.rules.message
        })
      } else {
        let shortUrl = val[0].url + '&imageView2/2/w/640'
        this.$emit('update:formField', {
          id: val[0].id,
          url: val[0].url,
          thum: shortUrl,
          type: val[0].type
        })
        this.$emit('submit')
      }
    }
  }
}
</script>
<style lang="scss">
  .c-form-image {
    .btn {
      color: #f56c6c;
      .delete-btn{
        padding: 4px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        align-items: center;
        &>span{
          width: 0;
          margin: 0;
          display: inline-block;
          line-height: 14px;
          overflow: hidden;
          transition: all .3s;
        }
        .add-placeholder{
          display: inline-block;
          font-size: 12px;
          }
        &:hover{
          &>span{
            width: max-content;
            margin-left: 5px;
          }
        }
      }
      &:hover {
        color: #f78989;
        svg {
          fill: #f78989;
        }
      }
      svg {
        top: 3px;
        right: 4px;
        position: relative;
        fill: #f56c6c;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .c-form-image {
    .btn {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
    .image-desc {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .formImage {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 100%;
      padding-bottom: 56.25%;
      cursor: pointer;
      vertical-align: top;
      display: inline-block;
      text-align: center;
      outline: none;
      position: relative;
      .box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
      i {
        font-size: 50px;
        color: #8c939d;
      }
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
      &:focus, &:hover {
        border-color: #409eff;
        color: #409eff;
      }
    }
  }
</style>

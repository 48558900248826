<template>
  <div id="media-filter">
    <el-radio-group v-model="filterType" @change="handleFilter" :disabled="filterBtnDisabled">
      <el-radio label="全部"></el-radio>
      <el-radio label="图片"></el-radio>
      <el-radio label="视频"></el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'media-filter',
  props: {
    prfilterType: {
      type: String,
      default: '全部'
    },
    filterBtnDisabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      filterType: '全部'
    }
  },
  watch: {
    prfilterType (val) {
      this.filterType = val
      if (val === 'reset') {
        this.filterType = '全部'
      }
      let type = this.handleFilter()
      this.$emit('filterQueryMedia', type)
    }
  },
  methods: {
    handleFilter () {
      let type = 'all'
      if (this.filterType === '全部') {
        type = 'all'
      } else if (this.filterType === '图片') {
        type = 'image'
      } else if (this.filterType === '视频') {
        type = 'video'
      }
      this.$emit('update:prfilterType', this.filterType)
      return type
    }
  }
}
</script>
<style lang="scss" scoped>
#media-filter {
  width: 100%;
  text-align: right;
  padding-right: 30px;
}
</style>
